import React, { useEffect, useRef, useState } from 'react'
import './budget.scss'
import { EditIcon, FeaturedIcon } from '../../Assets/Icons'
import { calculateStreamsAtPrice, calculateStreamsForPrice, formatNumberWithCommas, getParams, packages } from '../../Constant/Utils'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'


import tierBorder from './../../Assets/Images/tierBorder.png'

function Budget() {
    let [data, setData] = useState({})
    const [budgetNumber, setBudgetNumber] = useState(100)
    const [budgetNumberPercent, setBudgetNumberPercent] = useState(0)
    const navigate = useNavigate()
    const [editButton, setEditButton] = useState(false)
    useEffect(() => {
        let param = getParams('data')
        console.log(param, 'param')
        setData(param)
    }, [])
    const next = () => {
        localStorage.setItem('campaign-data', JSON.stringify(data))
        navigate(`/create-campaign/review-campaign`)
    }



    const spanRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if (spanRef.current && inputRef.current) {
            // Adjust input width based on the span width plus some padding
            inputRef.current.style.width = `${spanRef.current.offsetWidth + 10}px`;
        }
    }, [budgetNumber]); // Runs whenever the budget changes



    return (
        <div className='Budget'>
            <div className='innerBudget'>
                <div className='amountRage'>
                    <div className='amount'>
                        <h3>
                            $
                            {editButton ? (
                                <input
                                    ref={inputRef}
                                    type="number"
                                    value={budgetNumber}
                                    style={{ width: `${String(budgetNumber).length}ch` }}
                                    min={100}
                                    max={5000}
                                    autoFocus
                                    onChange={(ev) => {
                                        // if(ev.target.value){
                                        console.log((ev.target.value > 5000 ? 5000 : ev.target.value / (5000 - 100)) * 100, 'sadasdasdasda')
                                        setBudgetNumber(ev.target.value > 5000 ? 5000 : ev.target.value)
                                        setBudgetNumberPercent((ev.target.value > 5000 ? 5000 : ev.target.value / (5000 - 100)) * 100)

                                        // setData({ ...data, budget: ev.target.value });
                                        // }else{
                                        //     setData({ ...data, budget: 50 });
                                        // }
                                    }}
                                    onBlur={() => {
                                        if (budgetNumber < 100) {
                                            setBudgetNumber(100)
                                            setBudgetNumberPercent((100 / (5000 - 100)) * 100)
                                            // setData({ ...data, budget: 50 });
                                        } else {
                                            for (let i = 0; i < packages.length; i++) {
                                                if (budgetNumber > packages[i].endPrice && budgetNumber < packages[i + 1].startPrice) {
                                                    setBudgetNumber(packages[i + 1].startPrice)
                                                    setBudgetNumberPercent(((packages[i + 1].startPrice - 100) / (5000 - 100)) * 100)
                                                    break;
                                                }
                                            }

                                        }
                                        // let active = packages.filter(a => budgetNumber > a.startPrice && (budgetNumber == 5000 ? budgetNumber <= a?.endPrice : budgetNumber <= a?.endPrice))?.[0]
                                        // let activeIndex =  packages.indexOf(active)
                                        // console.log(activeIndex,active,'activeactiveactive')
                                        // let next = 
                                        setEditButton(false)
                                    }} // Close edit mode when input loses focus
                                />
                            ) : (
                                <>
                                    {budgetNumber || 50}
                                    <sup onClick={() => setEditButton(true)} style={{ cursor: 'pointer' }}>
                                        <EditIcon />
                                    </sup>
                                </>
                            )}
                            {/* Hidden span to calculate width based on content */}
                            <span
                                ref={spanRef}
                                style={{
                                    position: 'absolute',
                                    visibility: 'hidden',
                                    whiteSpace: 'pre',
                                }}
                            >
                                {budgetNumber}
                            </span>
                        </h3>
                        <p>Estimated <br /> <span>{calculateStreamsForPrice(Number(budgetNumber < 100 ? 100 : budgetNumber > 5000 ? 5000 : budgetNumber)).text}</span> streams</p>
                    </div>
                    <div className='rangeSelector'>
                        <span>$100</span>
                        <div className='rageSelectStepDiv'>
                            {/* {Array.from({ length: 21 }, (_, index) => {
                                let number = 0
                                return (
                                    <span key={index} className='rageSelectStep'
                                        onClick={() => {
                                            setBudgetNumberPercent((500 * (index) / (5000 - 50)) * 100)
                                            setBudgetNumber(500 * (index))
                                        }}
                                    ></span>
                                )
                            })} */}
                            <div className='progressDiv'>
                                <div className='Progress' style={{ width: `${Math.floor(budgetNumberPercent)}%` }}></div>
                            </div>
                            <input type='range' min={100} max={5000} step={100} value={budgetNumber || 100} onChange={(ev) => {
                                console.log(ev)
                                console.log(ev.target.value, 'sadasdasdasda')
                                setBudgetNumber(ev.target.value)
                                setBudgetNumberPercent(((ev.target.value - 100) / (5000 - 100)) * 100)
                                // setBudgetNumberPercent(((100 - 100) / (5000 - 100)) * 100)
                                // let obj = { ...data }
                                // obj.budget = String(ev.target.value)
                                // setData(obj)
                            }} />
                        </div>
                        <span onClick={() => {
                            console.log(budgetNumber, 'budgetNumberbudgetNumberbudgetNumber')
                        }}>$5,000</span>
                    </div>
                    <div className='packages'>
                        <div className='innerPackages'>
                            {packages.map((a, i) => {
                                let active = budgetNumber >= a.startPrice && (budgetNumber == 5000 ? budgetNumber <= a?.endPrice : budgetNumber <= a?.endPrice)
                                return (
                                    <div className={`packageDiv ${a.name} ${active ? 'active' : ''}`}>

                                        <div className='packageDetail' onClick={() => {
                                            if (window.innerWidth >= 1024) { // Example: Desktop width threshold (1024px or more)
                                                setBudgetNumber(a?.startPrice);
                                                setBudgetNumberPercent(((a?.startPrice - 100) / (5000 - 100)) * 100);
                                            }
                                        }}>
                                            <div className='innerPackageDetail'>
                                                <div className='packageName'>
                                                    <h4>{a.name}</h4>
                                                    <div className='packageImage'>
                                                        <img onClick={() => {
                                                            console.log(budgetNumber, a.startPrice, a.endPrice)
                                                        }} src={a.img} />
                                                    </div>
                                                    <p>(${a?.startPrice} - ${a?.endPrice})</p>
                                                </div>
                                                <div className='packageFeatures'>
                                                    <p className='discountAmount'>{a?.discount > 0 ?
                                                        <>
                                                            <span>{a?.discount}%</span> discount
                                                        </>
                                                        : null} </p>
                                                    <p><span>{a.estimateStreams}</span> estimated streams</p>
                                                </div>
                                            </div>
                                        </div>
                                        {active ?
                                            <div className='tierHover'>
                                                <button className='campaign' onClick={() => {
                                                    let obj = {
                                                        track_name: "",
                                                        artist_name: "",
                                                        track_link: "",
                                                        spotify_link: "",
                                                        spotify_uri: "",
                                                        track_released: true,
                                                        track_lyrics: [],
                                                        budget: Number(budgetNumber),
                                                        start_date: dayjs(new Date()).toISOString(),
                                                        end_date: "",
                                                        campaign_type: "one time",
                                                        track_image: "",
                                                        userId: "",
                                                    }
                                                    localStorage.setItem("campaign-data", JSON.stringify(obj));
                                                    navigate(`/create-campaign/add-track`)
                                                }}>Start a campaign</button>
                                            </div>
                                            : null}
                                    </div>
                                )
                            })}
                        </div>
                        <div className='startCampaign'>
                            <button onClick={() => {
                                let obj = {
                                    track_name: "",
                                    artist_name: "",
                                    track_link: "",
                                    spotify_link: "",
                                    spotify_uri: "",
                                    track_released: true,
                                    track_lyrics: [],
                                    budget: Number(budgetNumber),
                                    start_date: dayjs(new Date()).toISOString(),
                                    end_date: "",
                                    campaign_type: "one time",
                                    track_image: "",
                                    userId: "",
                                }
                                localStorage.setItem("campaign-data", JSON.stringify(obj));
                                navigate(`/create-campaign/add-track`)
                            }}>Start Campaign</button>
                        </div>
                    </div>
                    {/* <div className='tierDiv'>
                        <div className='currentTier'>
                            <div className='tierHover'>
                                <button className='campaign' onClick={() => {
                                    let obj = {
                                        track_name: "",
                                        artist_name: "",
                                        track_link: "",
                                        spotify_link: "",
                                        spotify_uri: "",
                                        track_released: true,
                                        track_lyrics: [],
                                        budget: Number(budgetNumber),
                                        start_date: dayjs(new Date()).toISOString(),
                                        end_date: "",
                                        campaign_type: "one time",
                                        track_image: "",
                                        userId: "",
                                    }
                                    localStorage.setItem("campaign-data", JSON.stringify(obj));
                                    navigate(`/create-campaign/add-track`)
                                }}>Start a campaign</button>
                            </div>
                            <h3>Current Tier</h3>
                            <p className='estimateStreamTier'>{calculateStreamsForPrice(Number(budgetNumber)).text}</p>
                        </div>
                        <div className='nextTier'
                        // onClick={() => {
                        //     setBudgetNumber(Number(budgetNumber) + 50)
                        //     setBudgetNumberPercent(((budgetNumber + 50) / (5000 - 50)) * 100)
                        // }}
                        >
                            <div className='tierHover'>
                                <button className='campaign' onClick={() => {
                                    let obj = {
                                        track_name: "",
                                        artist_name: "",
                                        track_link: "",
                                        spotify_link: "",
                                        spotify_uri: "",
                                        track_released: true,
                                        track_lyrics: [],
                                        budget: Number(budgetNumber) + 50,
                                        start_date: dayjs(new Date()).toISOString(),
                                        end_date: "",
                                        campaign_type: "one time",
                                        track_image: "",
                                        userId: "",
                                    }
                                    localStorage.setItem("campaign-data", JSON.stringify(obj));
                                    navigate(`/create-campaign/add-track`)
                                }}>Start a campaign</button>
                            </div>
                            <h3>Next Tier</h3>
                            <p className='estimateStreamTier'>{calculateStreamsForPrice(Number(budgetNumber) + 50).text}</p>
                            <p className='increaseText'>Increase your budget by $50 and get {calculateStreamsForPrice(Number(budgetNumber) + 50).sumOfStreams} additional streams</p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Budget