import { message, Switch } from "antd";
import React, { useEffect, useState } from "react";
import "./addTrack.scss";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getSongDetail, getToken } from "../../../module/features/Spotify";
import debounce from "lodash.debounce";
import { extractId, getParams } from "../../../Constant/Utils";
import { addArtistCampaigns } from "../../../module/features/Artist";
import { useNavigate } from "react-router-dom";
import { DatePicker } from 'antd'
import dayjs from 'dayjs'

function AddTrack() {
  let [data, setData] = useState({
    track_name: "",
    artist_name: "",
    artist_email: "",
    track_link: "",
    spotify_link: "",
    spotify_uri: "",
    track_released: false,
    track_lyrics: [],
    budget: 100,
    start_date: dayjs(new Date()).toISOString(),
    end_date: "",
    campaign_type: "one time",
    track_image: "",
    userId: "",
  })
  const [notReleased, setNotReleased] = useState(false);
  const track = useSelector((e) => e.spotifySlice.track);
  const user = useSelector((e) => e.authenticationSlice.user);
  const auth0User = useSelector(e => e.authenticationSlice.auth0User)
  const [dataReleased, setDataReleased] = useState({
    track_name: "",
    artist_name: "",
    artist_email: "",
    track_link: "",
    spotify_link: "",
    spotify_uri: "",
    track_released: false,
    track_lyrics: [],
    budget: 100,
    start_date: dayjs(new Date()).toISOString(),
    end_date: "",
    campaign_type: "one time",
    track_image: "",
    userId: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleChange = (e) => {
    let obj = { ...dataReleased }
    obj.track_released = !notReleased
    if (obj.track_released) {
      setDataReleased(obj)
    } else {
      setData(obj)
    }
    setNotReleased(!notReleased);
  };
  const [releasedLyric, setReleasedLyric] = useState(false);
  const handleChangeLyric = (e) => {
    let obj = { ...dataReleased }
    obj.track_lyrics = []
    setDataReleased(obj)
    setReleasedLyric(!releasedLyric);
  };
  const [url, setUrl] = useState("");
  useEffect(() => {
    if (auth0User && auth0User?.email) {
      let obj = notReleased ? { ...data } : { ...dataReleased }
      obj.artist_email = auth0User?.email
      if (notReleased) {
        console.log(obj, 'nbnbnb')
        setData(obj)
      } else {
        console.log(obj, 'nbnbnb')
        setDataReleased(obj)
      }
    }
  }, [auth0User, notReleased])

  useEffect(() => {
    if (!notReleased) {
      const debounceId = setTimeout(() => {
        console.log(url, "dataReleased");

        dispatch(
          getSongDetail({
            q: extractId(url),
          })
        );
      }, 1000);

      return () => {
        clearTimeout(debounceId);
      };
    }
  }, [url, notReleased]);
  const next = () => {
    // if (validate()) {
    console.log(dataReleased, "dataReleased");
    localStorage.setItem("campaign-data", !notReleased ? JSON.stringify(dataReleased) : JSON.stringify(data));
    navigate(`/create-campaign/set-budget-duration`);
    // }else{
    //   message.error('Please fill all the fields')
    // }
  };
  useEffect(() => {
    let param = getParams("data");
    if (param) {
      console.log(param?.track_released, 'sdadasdas')
      setNotReleased(!param?.track_released)
      setUrl(param?.track_link)
    }
  }, [])
  useEffect(() => {
    let param = getParams("data");
    if (track && !notReleased) {
      console.log(track, notReleased, 'sadasdasdaseqweqweqw')
      let obj = {
        ...dataReleased,
        track_name: track.name || param?.track_name || "",
        artist_name: track?.artists[0]?.name || param?.artist_name || "",
        artist_email: auth0User?.email || param?.artist_email || "",
        track_link: url || param?.track_link || "",
        spotify_link: track?.external_urls?.spotify || param?.spotify_link || "",
        spotify_uri: track?.uri || param?.spotify_uri || "",
        track_released: true,
        track_lyrics: param?.track_lyrics || [],
        budget: param?.budget || 100,
        start_date: param?.start_date || dayjs(new Date()).toISOString(),
        end_date: param?.end_date || "",
        campaign_type: param?.campaign_type || "one time",
        track_image: param?.track_image || track?.album?.images[0]?.url || "",
        userId: user?.userId || "",
      };
      // obj.track_name = track.name;
      // obj.artist_name = track?.artists[0]?.name;
      // obj.track_link = url;
      // obj.spotify_link = track?.external_urls?.spotify;
      // obj.spotify_uri = track?.uri
      // obj.track_released = true;
      // obj.userId = user?.userId || '';
      // obj.track_image = track?.album?.images[0]?.url;
      // if (param) {
      //   setUrl(param?.track_link);
      // }
      setDataReleased({ ...dataReleased, ...obj });
    } else {
      // if (notReleased) {
      setReleasedLyric(param?.track_lyrics?.length ? true : false)
      console.log(param, 'sadasdasdaseqweqweqw')
      setData({
        track_name: !param?.track_released ? param?.track_name : "",
        artist_name: !param?.track_released ? param?.artist_name : "",
        artist_email: !param?.track_released ? (auth0User?.email || param?.artist_email) : "",
        track_link: !param?.track_released ? param?.track_link : "",
        spotify_link: !param?.track_released ? param?.spotify_link : "",
        spotify_uri: !param?.track_released ? param?.spotify_uri : "",
        track_released: notReleased ? false : true,
        track_lyrics: !param?.track_released ? param?.track_lyrics : [],
        budget: !param?.track_released ? param?.budget : 100,
        start_date: !param?.track_released ? param?.start_date : dayjs(new Date()).toISOString(),
        end_date: !param?.track_released ? param?.end_date : "",
        campaign_type: !param?.track_released ? param?.campaign_type : "one time",
        track_image: !param?.track_released ? param?.track_image : "",
        userId: user?.userId || "",
      });
      // }
    }
  }, [track, notReleased, auth0User])
  const validate = () => {
    let param = getParams("data");
    let obj = !notReleased ? { ...dataReleased } : { ...data }
    console.log(notReleased, dataReleased, data, 'sadasdasdsadasdas', obj)
    // console.log(param?.track_released ? { ...dataReleased,eqw:'vxcvcx' } : { ...data,das:'adasd' })
    if (notReleased) {
      if (obj?.track_name &&
        obj?.artist_name &&
        // obj?.spotify_uri &&
        obj.artist_email &&
        obj?.start_date) {
        // console.log(obj, releasedLyric, 'sadasdasdasdasd')
        if (releasedLyric) {
          console.log('runnnnnnnnnn', releasedLyric, obj, obj?.track_lyrics && obj?.track_lyrics?.length, obj?.track_lyrics)
          if (obj?.track_lyrics && obj?.track_lyrics?.length) {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      } else {
        return true
      }
    } else {
      console.log({
        track_name: obj.track_name,
        artist_name: obj.artist_name,
        track_link: obj.track_link,
        track_released: obj.track_released,
      }, 'sadasdasdasdasd')
      if (obj.track_name && obj.artist_name && obj.track_link && obj.track_released && obj.artist_email) {
        if (releasedLyric) {
          if (obj?.track_lyrics?.length) {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      } else {
        return true
      }
    }
  }
  return (
    <div className="AddTrack">
      <div className="innerAddTrack">

        {!notReleased ? (
          <div>
            <div className="spotifyLink">
              <h4>Spotify song link</h4>
              <input
                className="compaignInput"
                placeholder="Your link here"
                value={dataReleased?.track_link}
                onChange={(ev) => {
                  let obj = { ...dataReleased };
                  obj.track_link = ev.target.value;
                  setUrl(ev.target.value);
                  setDataReleased(obj);
                }}
              />
              {dataReleased?.track_link ?
                <div className="songDetail">
                  <div className="imageName">
                    <img src={track?.album?.images[0]?.url} />
                    <div className="songNameArtist">
                      <p>{track?.name}</p>
                      <p>{track?.artists[0]?.name}</p>
                    </div>
                  </div>
                  {/* <div className="songDescr">
                  <p>Lorem Ipsum is simply dummy text of the <br /> printing and typesetting industry.</p>
                  </div> */}
                </div>
                : null}
              {auth0User?.email ? null :
                <>
                  <h4>Your email address *</h4>
                  <input
                    className="compaignInput"
                    placeholder="Your email address"
                    value={dataReleased?.artist_email}
                    onChange={(ev) => {
                      let obj = { ...dataReleased };
                      obj.artist_email = ev.target.value;
                      setDataReleased(obj);
                    }}
                  />
                </>
              }

            </div>
          </div>
        ) : null}
        <div className="realeaseDate releaseSwitchDiv">
          <div className="switchDiv">
            <div
              className="SwitchPlayListSpannerRows_text_songplace_only"
              style={{ display: "flex", alignItems: "center" }}
            >
              {/* <BulkModeCTAStyle checked={released} onChange={handleChange} /> */}
              <Switch
                checked={notReleased}
                onChange={handleChange}
                className="customeStyleSwitch"
              />
              {/* <p className="text_songplace_only">{useText}</p> */}
            </div>
          </div>
          <p>Please check this box if your song is not yet released</p>

        </div>
        {notReleased ? (
          <div>
            <div className="spotifyLink">
              <h4>Artist name</h4>
              <input
                className="compaignInput"
                placeholder="Artist name"
                value={data?.artist_name}
                onChange={(ev) => {
                  let obj = { ...data };
                  obj.artist_name = ev.target.value;
                  setData(obj);
                }}
              />
              {auth0User?.email ? null :
                <>
                  <h4>Your email address *</h4>
                  <input
                    className="compaignInput"
                    placeholder="Your email address"
                    value={data?.artist_email}
                    onChange={(ev) => {
                      let obj = { ...data };
                      obj.artist_email = ev.target.value;
                      setData(obj);
                    }}
                  />
                </>
              }
              <h4>Song name</h4>
              <input
                className="compaignInput"
                placeholder="Song name"
                value={data?.track_name}
                onChange={(ev) => {
                  let obj = { ...data };
                  obj.track_name = ev.target.value;
                  setData(obj);
                }}
              />
              <h4>Song URI (Optional)</h4>
              <input
                className="compaignInput"
                placeholder="Song URI "
                value={data?.spotify_uri}
                onChange={(ev) => {
                  let obj = { ...data };
                  obj.spotify_uri = ev.target.value;
                  setData(obj);
                }}
              />
              <h4>Private listen link (Optional)</h4>
              <input
                className="compaignInput"
                placeholder="SoundCloud/Dropbox/YouTube link"
                value={data?.track_link}
                onChange={(ev) => {
                  let obj = { ...data };
                  obj.track_link = ev.target.value;
                  setData(obj);
                }}
              />
              <h4>Release date</h4>
              <DatePicker
                className="compaignInput"
                format={"DD-MM-YYYY"}
                value={dayjs(data?.start_date || new Date())}
                disabledDate={(current) => current && current < dayjs().startOf('day')} // Disable future dates
                onChange={(ev) => {
                  let date = dayjs(ev).format('MM/DD/YYYY')
                  console.log(date, new Date(date).getTime(), 'dsadasdasdasdas');

                  let obj = { ...data };
                  if (ev) {
                    obj.start_date = new Date(date).getTime();
                    setData(obj);
                  }
                }}
              />
              {/* <div className='songDetail'>
              <div className="imageName">
                <img src='./static/Images/songImage.png' />
                <div className='songNameArtist'>
                  <p>Song name</p>
                  <p>Artist</p>
                </div>
              </div>
              <div className="songDescr">
                <p>Lorem Ipsum is simply dummy text of the <br /> printing and typesetting industry.</p>
              </div>
            </div> */}
            </div>
          </div>
        ) : null}
        <div className="realeaseDate">
          <div className="switchDiv">
            <div
              className="SwitchPlayListSpannerRows_text_songplace_only"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Switch
                className="customeStyleSwitch"
                checked={releasedLyric}
                onChange={handleChangeLyric}
              />
            </div>
          </div>
          <p>Does your song have lyrics?</p>

        </div>
        {releasedLyric ? (
          <div className="lyricLanguage">
            <button
              className={`lyricLangBtn 
          ${(notReleased ? data : dataReleased)?.track_lyrics?.includes("English") ? "active" : ""}`}
              onClick={() => {
                let obj = notReleased ? { ...data } : { ...dataReleased };
                let arr = obj.track_lyrics ? [...obj.track_lyrics] : [];
                if (arr.includes("English")) {
                  arr.splice(arr.indexOf("English"), 1);
                } else {
                  arr.push("English");
                }
                obj.track_lyrics = arr;
                if (notReleased) {
                  setData(obj);
                } else {
                  setDataReleased(obj);
                }
              }}
            >
              English
            </button>
            <button
              className={`lyricLangBtn 
          ${(notReleased ? data : dataReleased)?.track_lyrics?.includes("Spanish") ? "active" : ""}`}
              onClick={() => {
                let obj = notReleased ? { ...data } : { ...dataReleased };
                let arr = obj.track_lyrics ? [...obj.track_lyrics] : [];
                if (arr.includes("Spanish")) {
                  arr.splice(arr.indexOf("Spanish"), 1);
                } else {
                  arr.push("Spanish");
                }
                obj.track_lyrics = arr;
                if (notReleased) {
                  setData(obj);
                } else {
                  setDataReleased(obj);
                }
              }}
            >
              Spanish
            </button>
            <button
              className={`lyricLangBtn 
        ${(notReleased ? data : dataReleased)?.track_lyrics?.includes("French") ? "active" : ""}`}
              onClick={() => {
                let obj = notReleased ? { ...data } : { ...dataReleased };
                let arr = obj.track_lyrics ? [...obj.track_lyrics] : [];
                if (arr.includes("French")) {
                  arr.splice(arr.indexOf("French"), 1);
                } else {
                  arr.push("French");
                }
                obj.track_lyrics = arr;
                if (notReleased) {
                  setData(obj);
                } else {
                  setDataReleased(obj);
                }
              }}
            >
              French
            </button>
            <button
              className={`lyricLangBtn 
            ${(notReleased ? data : dataReleased)?.track_lyrics?.includes("German") ? "active" : ""}`}
              onClick={() => {
                let obj = notReleased ? { ...data } : { ...dataReleased };
                let arr = obj.track_lyrics ? [...obj.track_lyrics] : [];
                if (arr.includes("German")) {
                  arr.splice(arr.indexOf("German"), 1);
                } else {
                  arr.push("German");
                }
                obj.track_lyrics = arr;
                if (notReleased) {
                  setData(obj);
                } else {
                  setDataReleased(obj);
                }
              }}
            >
              German
            </button>
            <button
              className={`lyricLangBtn 
          ${(notReleased ? data : dataReleased)?.track_lyrics?.includes("Arabic") ? "active" : ""}`}
              onClick={() => {
                let obj = notReleased ? { ...data } : { ...dataReleased };
                let arr = obj.track_lyrics ? [...obj.track_lyrics] : [];
                if (arr.includes("Arabic")) {
                  arr.splice(arr.indexOf("Arabic"), 1);
                } else {
                  arr.push("Arabic");
                }
                obj.track_lyrics = arr;
                if (notReleased) {
                  setData(obj);
                } else {
                  setDataReleased(obj);
                }
              }}
            >
              Arabic
            </button>

            <button
              className={`lyricLangBtn 
          ${(notReleased ? data : dataReleased)?.track_lyrics?.includes("Hindi") ? "active" : ""}`}
              onClick={() => {
                let obj = notReleased ? { ...data } : { ...dataReleased };
                let arr = obj.track_lyrics ? [...obj.track_lyrics] : [];
                if (arr.includes("Hindi")) {
                  arr.splice(arr.indexOf("Hindi"), 1);
                } else {
                  arr.push("Hindi");
                }
                obj.track_lyrics = arr;
                if (notReleased) {
                  setData(obj);
                } else {
                  setDataReleased(obj);
                }
              }}
            >
              Hindi
            </button>
            <button
              className={`lyricLangBtn 
          ${(notReleased ? data : dataReleased)?.track_lyrics?.includes("Others") ? "active" : ""}`}
              onClick={() => {
                let obj = notReleased ? { ...data } : { ...dataReleased };
                let arr = obj.track_lyrics ? [...obj.track_lyrics] : [];
                if (arr.includes("Others")) {
                  arr.splice(arr.indexOf("Others"), 1);
                } else {
                  arr.push("Others");
                }
                obj.track_lyrics = arr;
                if (notReleased) {
                  setData(obj);
                } else {
                  setDataReleased(obj);
                }
              }}
            >
              Others
            </button>
          </div>
        ) : null}
      </div>
      <div className="stepsDiv">
        <p></p>
        <button onClick={next} disabled={validate()}>
          Next step
        </button>
      </div>
    </div>
  );
}

export default AddTrack;
