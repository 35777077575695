import React, { useEffect } from 'react'
import './campaign.scss'
import songImage from './../../Assets/Images/question-mark.jpg'
import dayjs from 'dayjs'
function Campaign({ index, data }) {
    useEffect(() => {
        console.log(data, 'datadatadata')
    }, [data])
    return (
        <div className='Campaign' key={index}>
            <div className='innerCampaign'>
                <div className='playlistDetail'>
                    <div className='songDetail'>
                        <img src={data?.track_image || songImage} />
                        <div className='name'>
                            <div className='nameDate'>
                                <h4 onClick={()=>{
                                    console.log(data,'datadatadata')
                                }}>{data?.track_name}</h4>
                                <span className='dateStart'>Start date: {dayjs(Number(data?.start_date)).format('DD-MM-YYYY')}</span>
                            </div>
                            <p>{data?.artist_name}</p>
                        </div>
                    </div>
                    <div className='songTags'>
                        {data?.track_lyrics.map((a, i) => {
                            return (
                                <div className='tags' key={i}>{a}</div>
                            )
                        })}
                    </div>
                </div>
                <div className='status'>
                    <p className='statusText'>Submitted <span className='statusDot'></span><span className='budget'>Budget: ${data.budget}</span></p>
                    {data.track_released ? null :
                        <p className='released'>(Pre-release)</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default Campaign


