import auth0 from "auth0-js";
import CryptoJS from 'crypto-js';
import bronze from './../Assets/Images/bronze.png'
import sliver from './../Assets/Images/silver.png'
import gold from './../Assets/Images/gold.png'
import patinum from './../Assets/Images/platinum.png'
export const webAuth = new auth0.WebAuth({
  domain: `dev-2z4p074m.us.auth0.com`,
  clientID: `${process.env.REACT_APP_AUTH0}`,
  // scope: "read:current_user read:users_app_metadata update:users_app_metadata   update:current_user_metadata",
});
export const authentication = new auth0.Authentication({
  domain: `dev-2z4p074m.us.auth0.com`,
  clientID: `${process.env.REACT_APP_AUTH0}`,
});

export async function Auth0Logout() {
  const domain = "dev-2z4p074m.us.auth0.com";
  const client_id = process.env.REACT_APP_AUTH0;
  const returnTo = window.location.origin;
  localStorage.removeItem("access_token");
  localStorage.removeItem("auth0-user");
  cookieManager.deleteCookie('token')
  window.location.replace(
    `https://${domain}/v2/logout?returnTo=${returnTo}&client_id=${client_id}`
  );
}

export const cookieManager = {
  setCookie: (name, value, minutes) => {
    let expires = "";
    if (minutes) {
      const date = new Date();
      date.setTime(date.getTime() + minutes * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + encodeURIComponent(value) + expires + "; path=/";
  },

  getCookie: (name) => {
    const nameEQ = name + "=";
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      cookie = cookie.trim();
      if (cookie.indexOf(nameEQ) === 0) {
        return decodeURIComponent(cookie.substring(nameEQ.length));
      }
    }
    return null;
  },

  deleteCookie: (name) => {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
  }
};
export const extractId = (url) => {
  if (url?.includes("https://open.spotify.com/track/")) {
    const slashSplits = url.split("/");
    const songId = slashSplits[4].split("?")[0]
    return songId
  } else if (url?.includes("spotify:track")) {
    const songId = url.split("spotify:track:")[1];
    return songId
  } else {
    return url
  }

}
export const getParams = (name) => {

  // console.log(window.location, 'paramparamparam')
  // const url = new URL(window.location)
  // let param = url?.searchParams?.get(name)
  // console.log(param, 'paramparamparam')
  // if (param) {
  return JSON.parse(localStorage.getItem('campaign-data'))
  // }
}
export const getEncryptedParam = (name) => {
  try {
    console.log(window.location, 'paramparamparam')
    const url = new URL(window.location)
    let param = url?.searchParams?.get(name)
    console.log(param, 'paramparam')
    let decryptedData = CryptoJS.AES.decrypt(param, 'data');
    console.log(decryptedData, 'paramparam')
    var originalText = decryptedData.toString(CryptoJS.enc.Utf8);
    console.log(originalText, 'paramparam')
    if (param) {
      return JSON.parse(originalText)
    }
  } catch (err) {
    console.log(err)
  }
}

export const getGreeting = () => {
  const currentHour = new Date().getHours();
  console.log(currentHour, 'currentHourcurrentHour')
  if (currentHour >= 6 && currentHour < 12) {
    return 'Good morning';
  } else if (currentHour >= 12 && currentHour < 18) {
    return 'Good afternoon';
  } else if (currentHour >= 18 && currentHour > 0) {
    return 'Good evening';
  } else if (currentHour >= 0 && currentHour < 6) {
    return 'Hello';
  }
};

export const trimSpaces = (sentence) => {
  return sentence.trim();
}
export const imageLink = (file) => {
  if (typeof file === 'string') {
    if (file.includes('http')) {
      return file
    } else {
      return process.env.REACT_APP_BACKEND_URI + '/artist/images/' + file
    }
  } else {
    return file
  }
}

export const extractIdArtist = (link) => {
  if (link.includes('https://open.spotify.com/artist/')) {
    let id = link?.split('https://open.spotify.com/artist/')[1]?.split('/')[0]
    console.log(id, 'ididididididi')
    return id
  }
}

export const formatNumberWithCommas = (number) => {
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

function formatNumber(value) {
  let number = Number(value)
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'; // Format for millions
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K'; // Format for thousands
  } else {
    return number.toString(); // Return the number as is for values less than 1000
  }
}


export const calculateStreamsAtPrice = (targetPrice) => {
  const prices = [];
  // Example usage
  const startPrice = 50; // Starting price
  const endPrice = 12800; // Maximum price (can be extended dynamically)
  const initialStream = 3; // Initial streams for the starting price
  const additionalStart = 2; // Starting value for the additional increment
  // const targetPrice = 15000; // Price for which to calculate streams (e.g., $15000)
  let currentPrice = startPrice;
  let currentStream = initialStream;
  let additionalValue = additionalStart;

  // Build the price-to-stream mapping dynamically
  while (currentPrice <= Math.max(targetPrice, endPrice)) {
    prices.push({ price: currentPrice, streams: currentStream });
    currentPrice *= 2; // Double the price
    currentStream = currentStream * 2 + additionalValue; // Multiply streams by 2 and add the additional value
    additionalValue *= 2; // Double the additional value
  }

  // Handle interpolation for a specific price
  for (let i = 0; i < prices.length - 1; i++) {
    const lower = prices[i];
    const upper = prices[i + 1];
    if (targetPrice >= lower.price && targetPrice <= upper.price) {
      // Interpolate streams
      const ratio = (targetPrice - lower.price) / (upper.price - lower.price);
      // return lower.streams + (upper.streams - lower.streams) * ratio;
      return formatNumber(String(lower.streams + (upper.streams - lower.streams) * ratio) + '000');
    }
  }
  console.log(prices, 'pricespricesprices')
  // If the targetPrice exceeds all calculated prices, return the last calculated stream value
  if (prices?.length && targetPrice > prices[prices.length - 1].price) {
    // return prices[prices.length - 1].streams;
    return formatNumber(String(prices[prices.length - 1].streams) + '000');
  }

  return null; // Fallback (should never happen with valid inputs)
}


// export function calculateStreamsForPrice(price) {
//   const increment = 50; // Increment in price
//   const baseRangeStart = 1000; // Starting stream range
//   const rangeStep = 2000; // Range increment per step
//   const baseAdditionalValue = 1000; // Starting additional value from 2nd step
//   const additionalValueIncrement = 200; // Increment for additional value

//   // Ensure the price is valid
//   if (price % increment !== 0 || price < increment) {
//     return `Invalid price. Price must be a multiple of $${increment} and at least $${increment}.`;
//   }

//   // Determine the step based on the price
//   const step = price / increment - 1; // Subtract 1 because step starts at 0 for $50

//   // Calculate the stream range
//   const rangeStart = baseRangeStart + step * rangeStep;
//   const rangeEnd = rangeStart + rangeStep;

//   // Calculate the additional value
//   const additionalValue = step === 0 ? 0 : baseAdditionalValue + (step - 1) * additionalValueIncrement;

//   // Return the result
//   return {
//     text: `Estimated ${rangeStart / 1000}K - ${rangeEnd / 1000}K Streams ${additionalValue === 0 ? "" : `+ ${additionalValue/1000}K additional`}`,
//     sumOfStreams: `${(rangeEnd + additionalValue) / 1000}K`
//   }

// }

export function calculateStreamsForPrice(price) {
  console.log(price, 'pricepriceprice')
  if (price) {

    if (price >= 100 && price < 500) {
      let streams = price / 0.03;
      return {
        text: `${formatNumberWithCommas(Math.round(streams))}`,
        sumOfStreams: `${formatNumber(streams)}`
      }
    } else if (price >= 500 && price < 1000) {
      let streams = price / 0.025;
      return {
        text: `${formatNumberWithCommas(Math.round(streams))}`,
        sumOfStreams: `${formatNumber(streams)}`
      }
    } else if (price >= 1000 && price < 2500) {
      let streams = price / 0.0225;
      return {
        text: `${formatNumberWithCommas(Math.round(streams))}`,
        sumOfStreams: `${formatNumber(streams)}`
      }
    } else if (price >= 2500 && price <= 5000) {
      let streams = price / 0.02;
      return {
        text: `${formatNumberWithCommas(Math.round(streams))}`,
        sumOfStreams: `${formatNumber(streams)}`
      }
    }
  } else {
    return {
      text: `Estimated`,
      sumOfStreams: ``
    }
  }
  // else {
  //   return {
  //     text: ``,
  //     sumOfStreams: ``
  //   }
  // }
}

export const packages = [
  {
    img: bronze,
    name: 'Bronze',
    startPrice: 100,
    endPrice: 450,
    discount: 0,
    estimateStreams: '3k - 13k'
  },
  {
    img: sliver,
    name: 'Silver',
    startPrice: 500,
    endPrice: 950,
    discount: 16.67,
    estimateStreams: '20k - 36k'
  },
  {
    img: gold,
    name: 'Gold',
    startPrice: 1000,
    endPrice: 2450,
    discount: 25,
    estimateStreams: '44k - 106k'
  },
  {
    img: patinum,
    name: 'Platinum',
    startPrice: 2500,
    endPrice: 5000,
    discount: 33.33,
    estimateStreams: '125k - 250k'
  }
]
export const getFormattedSpotifyUrl = (url) => {
  const regex = /\/artist\/([a-zA-Z0-9]+)/; // Regex to match artist ID
  const match = url.match(regex);

  if (match && match[1]) {
    const artistId = match[1];
    return `https://open.spotify.com/artist/${artistId}/discography/single`;
  } else {
    throw new Error("Invalid Spotify URL: Unable to extract artist ID");
  }
}