import React from 'react'
import aboutUs from './../../Assets/AboutUs.json'
import { useLottie } from 'lottie-react';

function AboutUsLottie() {
    const optionsAbout = {
        animationData: aboutUs,
        loop: false,
    };
    const { View } = useLottie(optionsAbout);
    return (
        <>
            {View}
        </>
    )
}

export default AboutUsLottie