import React, { useEffect, useState } from 'react';
import './CompleteProfile.scss';
import imagePlaceholder from './../../Assets/Images/userav.png';
import ReactFlagsSelect from 'react-flags-select';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, updateArtist, updateArtistImage } from '../../module/features/Authentication';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../Constant/Spinner';
import { MinusIcon } from '../../Assets/Icons';
import { imageLink, trimSpaces } from '../../Constant/Utils';

function CompleteProfile() {
    const [selected, setSelected] = useState("");
    const [loader, setLoader] = useState(false);
    const [imageFile, setImageFile] = useState(null); // New state for image file
    const [previewImage, setPreviewImage] = useState(''); // New state for image preview
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [additionalLink, setAdditionalLink] = useState([{ value: '' }]);
    const [showAllType, setShowAllType] = useState(2)
    const [showAllGenre, setShowAllGenre] = useState(2)
    const user = useSelector(e => e.authenticationSlice.user);
    const [data, setData] = useState({
        name: '',
        bio: '',
        image: '',
        musicgenre: [''],
        musictype: [''],
        country: '',
        additional_links: [''],
        sportify_artist_url: ''
    });

    useEffect(() => {
        if (user) {
            setData({
                name: user?.name || '',
                bio: user?.bio || '',
                musicgenre: user?.musicgenre || [''],
                image: user?.image || '',
                musictype: user?.musictype || [''],
                country: user?.country || '',
                additional_links: user?.additional_links || [''],
                sportify_artist_url: user?.sportify_artist_url || ''

            });
            const arr = user?.additional_links?.map((a) => ({ value: a })) || [{ value: '' }];
            setAdditionalLink(arr.length ? arr : [{ value: '' }]);
        }
    }, [user]);

    // Validation state
    const [errors, setErrors] = useState({});

    // Handle input change and update state
    const handleChange = (field, value) => {
        setData(prevData => ({ ...prevData, [field]: value }));
    };

    // Handle additional link changes
    const handleAdditionalLinkChange = (index, value) => {
        const updatedLinks = [...additionalLink];
        updatedLinks[index].value = value;
        setAdditionalLink(updatedLinks);
    };
    const handleRemoveLink = (index) => {
        const updatedLinks = additionalLink.filter((_, i) => i !== index);
        setAdditionalLink(updatedLinks);
    };

    // Handle file upload and preview
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
            setPreviewImage(URL.createObjectURL(file)); // Set preview image
        }
    };

    // Validation function
    const validate = () => {
        const newErrors = {};
        if (!data.name.trim()) newErrors.name = "Name is required";
        if (!data.bio.trim()) newErrors.bio = "Bio is required";
        if (!data.country) newErrors.country = "Country is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const callback = () => {
        dispatch(getProfile());
        setLoader(false);
        navigate('/dashboard');
    };

    useEffect(() => {
        console.log(data, 'useruseruser')
    }, [data])

    const handleSubmit = (e) => {
        e.preventDefault();
        // if (validate()) {
            setLoader(true);
            const updatedData = {
                ...data,
                name: trimSpaces(data.name),
                // image:imageFile?.name,
                additional_links: additionalLink.map(link => link.value)
            };
            console.log(imageFile, 'imageFileimageFile')
            dispatch(updateArtist({ data: updatedData, id: user?._id, callback })); // Include image file in action payload
            dispatch(updateArtistImage({ id: user?._id, imageFile, callback }))
        // } else {
        //     console.log("Validation failed.");
        // }
    };

    return (
        <div className='CompleteProfile'>
            <div className='innerCompleteProfile'>
                <h3>Personal information</h3>
                <form className='form' onSubmit={handleSubmit}>
                    <div className='innerForm'>
                        <div className='formDiv'>
                            <div className='uploadPicture'>
                                <label>
                                    <img src={imageLink(previewImage || data?.image || imagePlaceholder)} alt="User avatar" />
                                    <input className='fileInput' type='file' onChange={handleFileChange} />
                                    <div className='uploadSvg'>
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="30px" width="30px" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M9 3 7.17 5H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2h-3.17L15 3H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"></path><path d="m12 17 1.25-2.75L16 13l-2.75-1.25L12 9l-1.25 2.75L8 13l2.75 1.25z"></path></svg>
                                    </div>
                                    <p>Upload a photo</p>
                                </label>
                            </div>
                            <div className='inputsSongplaceDiv'>

                                <label className='inputsSongplace'>
                                    Name
                                    <div className='inputSongplaceDiv'>
                                        <input
                                            placeholder='Your name here'
                                            value={data.name}
                                            onChange={(e) => handleChange('name', e.target.value)}
                                        />
                                    </div>
                                    {errors.name && <p className="error">{errors.name}</p>}
                                </label>
                                <label className='inputsSongplace'>
                                    Add bio
                                    <div className='inputSongplaceDiv'>
                                        <textarea
                                            draggable={false}
                                            rows={4}
                                            placeholder='Tell us about you'
                                            value={data.bio}
                                            onChange={(e) => handleChange('bio', e.target.value)}
                                        />
                                    </div>
                                    {errors.bio && <p className="error">{errors.bio}</p>}
                                </label>
                            </div>
                        </div>
                        <div className='formDiv'>
                            <div className='formSection '>
                                <div className='selectBoxDiv selectMusicType'>
                                    Select your music type
                                    <div className='selectBox'>
                                        {["Vocal", "Instrumental", "Electronic", "Acoustic"].map((type, i) => i < showAllType ? (
                                            <button
                                                key={type}
                                                type="button"
                                                className={`selectBoxBtn ${data.musictype.includes(type) ? 'active' : ''}`}
                                                onClick={() => {
                                                    const arr = [...data.musictype];
                                                    if (arr.includes(type)) {
                                                        arr.splice(arr.indexOf(type), 1);
                                                    } else {
                                                        arr.push(type);
                                                    }
                                                    handleChange('musictype', arr);
                                                }}
                                            >
                                                {type}
                                            </button>
                                        ) : null)}
                                    </div>
                                    {showAllType === 4 ?
                                        <p className='showMoreOption' onClick={() =>
                                            setShowAllType(2)
                                        }>+ Show less options</p>
                                        :
                                        <p className='showMoreOption' onClick={() =>
                                            setShowAllType(4)
                                        }>+ Show more options</p>}
                                </div>
                                <hr />
                                {/* 
                            <label>
                                Country
                                <ReactFlagsSelect
                                    className='ReactFlagsSelect'
                                    selected={data.country}
                                    onSelect={(code) => handleChange('country', code)}
                                    searchable
                                    showSelectedLabel
                                />
                                {errors.country && <p className="error">{errors.country}</p>}
                            </label> */}
                            </div>
                            <div className='formSection'>
                                <div className='selectBoxDiv'>
                                    Select your music genre
                                    <div className='selectBox'>
                                        {["Rock", "Jazz", "Pop", "Classical"].map((genre, i) => i < showAllGenre ? (
                                            <button
                                                key={genre}
                                                type="button"
                                                className={`selectBoxBtn ${data.musicgenre.includes(genre) ? 'active' : ''}`}
                                                onClick={() => {
                                                    const arr = [...data.musicgenre];
                                                    if (arr.includes(genre)) {
                                                        arr.splice(arr.indexOf(genre), 1);
                                                    } else {
                                                        arr.push(genre);
                                                    }
                                                    handleChange('musicgenre', arr);
                                                }}
                                            >
                                                {genre}
                                            </button>
                                        ) : null)}
                                    </div>
                                    {showAllGenre === 4 ?
                                        <p className='showMoreOption' onClick={() =>
                                            setShowAllGenre(2)
                                        }>+ Show less options</p>
                                        :
                                        <p className='showMoreOption' onClick={() =>
                                            setShowAllGenre(4)
                                        }>+ Show more options</p>}
                                </div>
                                <hr />

                                <label>
                                    Add additional links (optional)
                                    {additionalLink.map((link, index) => (
                                        <div className='additonInputDiv'>

                                            <input
                                                key={index}
                                                placeholder='Social link'
                                                value={link.value}
                                                onChange={(e) => handleAdditionalLinkChange(index, e.target.value)}
                                            />
                                            <button
                                                className='minusButton'
                                                onClick={() => handleRemoveLink(index)}
                                            >
                                                <MinusIcon />
                                            </button>
                                        </div>

                                    ))}
                                </label>
                                <span
                                    className='addMoreLink'
                                    onClick={() => setAdditionalLink([...additionalLink, { value: '' }])}
                                >
                                    + Add more links
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='submitButtonDiv'>
                        <button className='submitButton'>Submit</button>
                    </div>
                </form>

            </div>
        </div>
    );
}

export default CompleteProfile;



