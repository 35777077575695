import React, { useState } from "react";
import './footer.scss'
import SongplaceLogo from './../../Assets/Images/songplace.png'
import intagramicon from './../../Assets/Images/Instagram.png'
import linkedIn from './../../Assets/Images/linkedIn.png'
import facebook from './../../Assets/Images/facebook.png'
import { useNavigate } from "react-router-dom";

const Footer = ({ handleAdd }) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate()

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  return (
    <div className="footer-container">
      <div className="footer-main">
        <div className="footer-section1">
          <img
            className="SideStyleImage"
            src={SongplaceLogo}
            alt="footer songplace"
          />
          <p className="footer-para">
            Focus on your streams — <br />
            We’ll handle the management.
          </p>
          <div className="footer-songplace-div">
            <p></p>
            <div className="socialIconsDiv">
              <a href="https://www.instagram.com/songplace.io" target="_blank">
              <img alt="intagramicon.svg" src={intagramicon} className="footer-icon" width="41" height="42" />
              </a>
              <a href="https://www.linkedin.com/showcase/songplace/" target="_blank">
              <img alt="linkedin.svg" src={linkedIn} className="footer-icon" width="41" height="42" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="footer-bottom">
        <div className="section4">
          <p className="bottom-para">
            © {new Date().getFullYear()} songplace Inc. All right reserved.
          </p>
        </div>

        <div className="section3">
          <p
            className="bottom-para bottom-para-link"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/terms-and-condition")}
          >
            Terms & conditions
          </p>
          <p
            className="bottom-para bottom-para-link"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/privacy-policy")}
          >
            Privacy policy
          </p>
          {/* <p
            className="bottom-para bottom-para-link"
            style={{ cursor: "pointer" }}
            onClick={() => handleAdd("policy")}
          >
            Privacy policy
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
