import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit'
import axios from 'axios'
import { cookieManager } from '../../Constant/Utils';
import { message } from 'antd';

const initialState = {
    curator: ""
}

export const getProfile = createAsyncThunk(
    "completeProfileSlice/getProfile",
    async (data) => {
        try {
            // function here

        } catch (error) {
            console.error(error);
        }
    });

export const curatorSlice = createSlice({
    name: 'curatorSlice',
    initialState,
    reducers: {
        addCurator: (state, action) => {
            try {
                let data = JSON.stringify(action.payload.data);
                // let token = cookieManager.getCookie('token')
                let config = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_BACKEND_URI}/curatorCampaign/createCurator`,
                    headers: {
                        "Content-Type": "application/json",
                        // 'authorization': token
                    },
                    data: data,
                };
                console.log('action runn', config)

                axios
                    .request(config)
                    .then((response) => {
                        action.payload.callback();
                        message.success("Thank you! Your details have been submitted and we will contact you as soon as possible.");
                    })
                    .catch((error) => {
                        console.log()
                        let errorMsg = Object.values(error.response.data.error.errors)?.length === 1 ? Object.values(error.response.data.error.errors)[0]?.message : "fill all fields with valid data";
                        message.error(errorMsg);
                        console.log(error)
                    });
            } catch (err) {
                console.log(err)
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProfile.pending, (state, action) => {
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.userProfile = action.payload;
            })
            .addCase(getProfile.rejected, (state, action) => {
            })
    }
})

export const { addCurator } = curatorSlice.actions

export default curatorSlice.reducer