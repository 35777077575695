import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit'
import axios from 'axios'
import { cookieManager } from '../../Constant/Utils';
import { message } from 'antd';

const initialState = {
    campaigns: [],
    notification: false
}

export const getArtistCampaigns = createAsyncThunk(
    "artistSlice/getArtistCampaigns",
    async (data) => {
        try {
            let auth0User = JSON.parse(localStorage.getItem('auth0-user'))
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BACKEND_URI}/artistCampaign/${auth0User?.email}/campaignData?userId=${auth0User?.sub}`,
                headers: {
                    // authorization: token,
                }
            };

            const response = await axios.request(config);
            console.log(response, 'responseresponse')
            return response?.data?.data

        } catch (error) {
            console.error(error);
        }
    });



export const getNotfication = createAsyncThunk(
    "artistSlice/getNotfication",
    async (data) => {
        try {
            // localStorage.setItem('notification', "true")

            return localStorage.getItem('notification');

        } catch (error) {
            console.error(error);
        }
    });

export const artistSlice = createSlice({
    name: 'artistSlice',
    initialState,
    reducers: {
        addArtistCampaigns: (state, action) => {
            try {


                let data = JSON.stringify(action.payload.data);
                let token = cookieManager.getCookie('token')
                let config = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_BACKEND_URI}/artistCampaign/createCampaign`,
                    headers: {
                        "Content-Type": "application/json",
                        'authorization': token
                    },
                    data: data,
                };
                console.log('action runn', config)

                axios
                    .request(config)
                    .then((response) => {
                        action.payload.callback();
                    })
                    .catch((error) => {
                        let errorMsg = error?.response?.data?.error || error?.response?.data?.message || "Please Try Again6";
                        message.error(errorMsg);
                        console.log(error)
                    });
            } catch (err) {
                console.log(err)
            }
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getArtistCampaigns.pending, (state, action) => {
            })
            .addCase(getArtistCampaigns.fulfilled, (state, action) => {
                state.campaigns = action.payload;
            })
            .addCase(getArtistCampaigns.rejected, (state, action) => {
            })
            .addCase(getNotfication.pending, (state, action) => {
            })
            .addCase(getNotfication.fulfilled, (state, action) => {
                state.notification = action.payload;
            })
            .addCase(getNotfication.rejected, (state, action) => {
            })


    }
})

export const { addArtistCampaigns } = artistSlice.actions

export default artistSlice.reducer