import React, { useState } from 'react'
import SongplaceLogo from './../../Assets/Images/songplace.png'
import './landingNavbar.scss'
import { Button, Drawer, Dropdown, Modal, Radio, Space } from 'antd';
import { BurgerIcon, MinusIcon } from '../../Assets/Icons';
import { useDispatch, useSelector } from 'react-redux';
import userImage from './../../Assets/Images/userav.png'
import { Auth0Logout, imageLink } from '../../Constant/Utils';
import { useNavigate } from 'react-router-dom';
import { addCurator } from '../../module/features/Curator';
function LandingNavbar() {
    const user = useSelector(e => e.authenticationSlice.user)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('left');
    const [data, setData] = useState({
        name: '',
        email: '',
        playlists: [
            { playlistURL: '', estimatedStreams: 0, genres: [] },
        ],
    });
    const [isModalOpen, setIsModalOpen] = useState(false)
    const callback = () => {
        setData({
            name: '',
            email: '',
            playlists: [
                { playlistURL: '', estimatedStreams: 0, genres: [] },
            ],
        })
        setIsModalOpen(false)
    }
    const handleOk = () => {
        console.log(data)
        dispatch(addCurator({
            data: data,
            callback: callback
        }))

    };
    const handleCancel = () => {
        setIsModalOpen(false)
        setData({
            name: '',
            email: '',
            playlists: [
                { playlistURL: '', estimatedStreams: 0, genres: [] },
            ],
        })

    };
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const onChange = (e) => {
        setPlacement(e.target.value);
    };
    const handleFormSubmit = async () => {
        const domain = "dev-2z4p074m.us.auth0.com";
        const client_id = process.env.REACT_APP_AUTH0;
        const audience = "https://dev-2z4p074m.us.auth0.com/api/v2/";
        const scope = "openid profile email";
        const response_type = "token";
        const redirect_uri = `${window.location.origin}/callback`;
        window.location.replace(
            `https://${domain}/authorize?` +
            `audience=${audience}&` +
            `scope=${scope}&` +
            `response_type=${response_type}&` +
            `client_id=${client_id}&` +
            `redirect_uri=${redirect_uri}`
        );
    };
    const items = [
        {
            label: (
                <div className='logoutNavbar' onClick={() => {
                    navigate('/dashboard')
                }}>
                    Dashboard
                </div>
            ),
            key: '0',
        },
        {
            label: (
                <div className='logoutNavbar' onClick={() => {
                    navigate('/create-campaign/add-track')
                }}>
                    Create Campaign
                </div>
            ),
            key: '1',
        },
        {
            label: (
                <div className='logoutNavbar' onClick={() => {
                    navigate('/dashboard/account-overview')
                }}>
                    Profile
                </div>
            ),
            key: '2',
        },
        {
            label: (
                <div className='logoutNavbar' onClick={() => {
                    Auth0Logout()
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                }}>
                    Logout
                </div>
            ),
            key: '3',
        }
    ];


    const handleInputChange = (field, value) => {
        setData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handlePlaylistChange = (index, field, value) => {
        const updatedPlaylists = [...data.playlists];
        updatedPlaylists[index][field] = value;
        setData((prevData) => ({
            ...prevData,
            playlists: updatedPlaylists,
        }));
    };

    const toggleGenre = (index, genre) => {
        setData((prevData) => {
            const updatedPlaylists = [...prevData.playlists];
            const currentGenres = updatedPlaylists[index].genres;

            // Toggle the genre
            if (currentGenres.includes(genre)) {
                updatedPlaylists[index].genres = currentGenres.filter((g) => g !== genre);
            } else {
                updatedPlaylists[index].genres = [...currentGenres, genre];
            }

            return { ...prevData, playlists: updatedPlaylists };
        });
    };

    const deletePlaylist = (index) => {
        setData((prevData) => {
            const updatedPlaylists = [...prevData.playlists];
            updatedPlaylists.splice(index, 1);
            return { ...prevData, playlists: updatedPlaylists };
        });
    };

    const addMorePlaylist = () => {
        setData((prevData) => ({
            ...prevData,
            playlists: [...prevData.playlists, { playlistURL: '', estimatedStreams: 0, genres: [] }],
        }));
    };
    return (
        <div className='LandingNavbar'>
            <div className='innerNavbar'>
                <div className='innerNavbarDiv'>

                    <div className='logoDiv'>
                        <img src={SongplaceLogo} />
                    </div>
                    <div className='navLinks hideMob'>
                        <div className='link'><a className='active' href='/'>Home</a></div>
                        <div className='link'><a className='' href='#budgetSection'>Pricing</a></div>
                        <div className='link'><a className='' href='#featuresSection'>Features</a></div>
                        <div className='link'><a className='' href='#aboutUsSection'>About</a></div>
                        <div className='link'><a className='' href='#newsLetterSection'>Contact</a></div>
                    </div>
                    {user ?
                        <Dropdown
                            menu={{
                                items,
                            }}
                            trigger={['click']}
                            overlayClassName='profileDropDown'
                        >
                            <div className='user'>
                                <img src={imageLink(user?.image)} />
                                <div className='userName'>
                                    {user?.name}
                                </div>
                            </div>
                        </Dropdown>
                        :
                        <div className='loginButtn hideMob'>
                            <div className='link' onClick={() => { setIsModalOpen(true) }}>
                                Are you a curator?
                            </div>
                            <button onClick={handleFormSubmit}>
                                Sign in
                            </button>
                        </div>}
                    <div className='burgerIcon' onClick={showDrawer}>
                        <BurgerIcon />
                    </div>
                </div>
            </div>
            <Drawer
                title={false}
                placement={placement}
                closable={false}
                onClose={onClose}
                open={open}
                key={placement}
                className='menuDrawer'
                rootClassName='menuDrawerRoot'

            >
                <div>
                    <div className='logoDiv'>
                        <img src={SongplaceLogo} />
                    </div>
                    <div className='navLinksDiv'>
                        <div className='navLinks'>
                            <div className='link'><a href='#heroSection' onClick={onClose}>Home</a></div>
                            <div className='link'><a href='#budgetSection' onClick={onClose}>Pricing</a></div>
                            <div className='link'><a href='#featuresSection' onClick={onClose}>Features</a></div>
                            <div className='link'><a href='#aboutUsSection' onClick={onClose}>About</a></div>
                            <div className='link'><a href='#newsLetterSection' onClick={onClose}>Contact</a></div>
                        </div>
                        <div className='loginButtn'>
                            <div className='link' onClick={() => { setIsModalOpen(true); onClose() }}>
                                Are you a creator?
                            </div>
                            <button onClick={handleFormSubmit}>
                                Sign In
                            </button>
                        </div>
                    </div>
                </div>
            </Drawer>
            <Modal
                className="LandingNavbarModal" footer={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className='innerLandingNavbarModal'>
                    <h3>Add your details</h3>
                    <p>
                        Share your playlist details and start earning by connecting with talented artists. Let’s make great music together!
                    </p>
                    <div className='formDiv'>
                        <input
                            placeholder='Name'
                            value={data.name}
                            onChange={(e) => handleInputChange('name', e.target.value)}
                        />
                        <input
                            placeholder='Email'
                            value={data.email}
                            onChange={(e) => handleInputChange('email', e.target.value)}
                        />

                        {data.playlists.map((playlist, index) => (
                            <div key={index} className='playlistFormData'>
                                {data.playlists.length > 1 ?
                                    <button className='deleteButton' onClick={() => deletePlaylist(index)}>
                                        <MinusIcon />
                                    </button>
                                    : null}
                                <label>
                                    <span>Playlist URL</span>
                                    <input
                                        placeholder='https://open.spotify.com/playlist/'
                                        value={playlist.playlistURL}
                                        onChange={(e) => handlePlaylistChange(index, 'playlistURL', e.target.value)}
                                    />
                                </label>
                                <label>
                                    <span>Estimated streams you can offer</span>
                                    <input
                                        type='number'
                                        value={playlist.estimatedStreams}
                                        onChange={(e) => handlePlaylistChange(index, 'estimatedStreams', e.target.value)}
                                    />
                                </label>
                                <label>
                                    <span>Select your Genre</span>
                                    <div className='genreDiv'>
                                        {["Dance", "Hiphop", "Minimal", "Solo", "Instrumental", "Intense", "Others"].map((genre) => (
                                            <div
                                                key={genre}
                                                className={`genre ${playlist.genres.includes(genre) ? 'selected' : ''}`}
                                                onClick={() => toggleGenre(index, genre)}
                                            >
                                                {genre}
                                            </div>
                                        ))}
                                    </div>
                                </label>
                            </div>
                        ))}
                    </div>

                    <button className='addMorePlaylsit' onClick={addMorePlaylist}>
                        Add more playlists
                        <span className='plusSign'>
                            <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 0V6.5" stroke="white" />
                                <path d="M7.25 3.25L0.75 3.25" stroke="white" />
                            </svg>
                        </span>
                    </button>
                </div>
                <div className='buttonDiv'>
                    <button className='cancelForm' onClick={handleCancel}>
                        Cancel
                    </button>
                    <button className='submitForm' onClick={handleOk}>
                        Submit
                    </button>

                </div>
            </Modal >
        </div >
    )
}

export default LandingNavbar