import React from 'react'
import './campaignSuccess.scss'
import { Modal } from 'antd'
import successImage from './../../../Assets/Images/undraw_completing_re_i7ap.png'
import Spinner from '../../../Constant/Spinner'
import { useSelector } from 'react-redux'

function CampaignSuccess({ isModalOpen,
    handleOk,
    handleCancel,
    loaderCampaign,
    loginFunction
}) {
    const auth0User = useSelector(e => e.authenticationSlice.auth0User)
    return (
        <Modal
            className="SubscriptionModal" closable={false} footer={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <div className="innerSubscriptionModal">
                {loaderCampaign ?
                    <>
                        <Spinner className="campaignLoader" />
                        <h3>Proceeding your campaign</h3>
                    </>
                    :
                    <>
                        <img alt="success-img" className="SuccessSubsImage" src={successImage} />
                        <h3>Campaign successfully submitted! </h3>
                        <p>Thank you for choosing Songplace. Our team will get back to you shortly</p>
                        {auth0User?.email ?
                            <button className="SubscribeBtn" onClick={() => handleOk("/dashboard/view-campaigns")}>Go to dashboard</button>
                            :
                            <button className="SubscribeBtn" onClick={() => handleOk('/create-campaign/add-track')}>Create more campaigns</button>
                        }
                        {auth0User?.email ? null :
                            <button className="SubscribeBtn" onClick={loginFunction}>See your campaigns</button>
                        }
                    </>
                }
            </div>
        </Modal>
    )
}

export default CampaignSuccess