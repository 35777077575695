import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    access_token: '',
    auth0User: null,
    user: null
}

export const getProfile = createAsyncThunk(
    "authenticationSlice/getProfile",
    async (data) => {
        try {
            if (localStorage.getItem('auth0-user') && localStorage.getItem('access_token')) {

                let access_token = localStorage.getItem('access_token')
                let auth0User = JSON.parse(localStorage.getItem('auth0-user'))

                let config = {
                    method: "get",
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_BACKEND_URI}/artist/${auth0User?.sub}/data`,
                    headers: {
                        // authorization: token,
                    }
                };

                const response = await axios.request(config);
                // return response.data
                if (response?.data?.data?.length) {
                    console.log(response?.data, 'responseresponse')
                    // data?.navigateToDashboard()
                    return {
                        access_token,
                        data: response.data?.data,
                        auth0User: auth0User
                    };

                }
            }
        } catch (error) {
            console.error(error);
        }



    });

export const authenticationSlice = createSlice({
    name: 'authenticationSlice',
    initialState,
    reducers: {
        addArtist: (state, action) => {
            let data = JSON.stringify(action.payload.data);
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BACKEND_URI}/artist/add`,
                headers: {
                    "Content-Type": "application/json"
                },
                data: JSON.parse(data),
            };
            axios
                .request(config)
                .then((response) => {
                    if (action.payload.callback) {
                        action.payload.callback()
                        console.log(response, 'responseresponse')
                    }
                })
                .catch((error) => {

                });
        },
        updateArtist: (state, action) => {
            // // Create a FormData object to store the image and other fields
            // const formData = new FormData();

            // // Append each data field to FormData
            // Object.entries(action.payload.data).forEach(([key, value]) => {
            //     formData.append(key, value);
            // });

            // // Append the image file if it exists
            // if (action.payload.imageFile) {
            //     formData.append('image', action.payload.imageFile);
            // }

            // Set up the axios configuration
            const config = {
                method: "patch",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BACKEND_URI}/artist/${action.payload.id}/update`,
                headers: {
                    // "Content-Type": "" // Set content type for file upload
                },
                data: action.payload.data, // Use formData instead of JSON data
            };

            // Make the request
            axios
                .request(config)
                .then((response) => {
                    if (action.payload.callback) {
                        action.payload.callback();
                        console.log(response, 'response');
                    }
                })
                .catch((error) => {
                    console.error("Error updating artist:", error);
                });
        },
        updateArtistImage: (state, action) => {
            // Create a FormData object to store the image and other fields
            const formData = new FormData();

            // Append the image file if it exists
            if (action.payload.imageFile) {
                formData.append('image', action.payload.imageFile);
            }

            // Set up the axios configuration
            const config = {
                method: "patch",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BACKEND_URI}/artist/${action.payload.id}/update/image`,
                headers: {
                    "Content-Type": "multipart/form-data" // Set content type for file upload
                },
                data: formData, // Use formData instead of JSON data
            };

            // Make the request
            axios
                .request(config)
                .then((response) => {
                    if (action.payload.callback) {
                        action.payload.callback();
                        console.log(response, 'response');
                    }
                })
                .catch((error) => {
                    console.error("Error updating artist:", error);
                });
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProfile.pending, (state) => {
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.access_token = action?.payload?.access_token;
                state.auth0User = action?.payload?.auth0User;
                state.user = action?.payload?.data?.length ? action?.payload?.data[0] : null;
            })
            .addCase(getProfile.rejected, (state) => {
            })
    }
})

export const { addArtist, updateArtist, updateArtistImage } = authenticationSlice.actions

export default authenticationSlice.reducer