import React, { useEffect, useState } from 'react'
import './sidebar.scss'
import logo from './../../Assets/Images/songplace.png'
import userAv from './../../Assets/Images/playListPic.png'
import { DashboardIcon, HomeIcon, SettingIcon, ThreeDotsIcon, User } from '../../Assets/Icons'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Dropdown } from 'antd'
import { Auth0Logout, imageLink } from '../../Constant/Utils'
function Sidebar({ onClose }) {
    const auth0User = useSelector(e => e.authenticationSlice.auth0User)
    const [pathname, setPathname] = useState('')
    const user = useSelector(e => e.authenticationSlice.user)
    const navigate = useNavigate()
    const items = [
        {
            label: <div onClick={() => {
                Auth0Logout()
            }}>Logout</div>,
            key: '0',
        },
    ]
    useEffect(() => {
        let pathName = window.location.pathname
        setPathname(pathName)
    }, [window.location.pathname])
    return (
        <div className='innerSidebar'>
            <div className='logo'>
                <img src={logo} />
            </div>
            <div className='userName'>
                <div className='userAvName'>
                    <img src={imageLink(user?.image || auth0User?.picture)} />
                    <p>{user?.name}</p>
                </div>
                <Dropdown
                    overlayClassName='dropdownSideBar'
                    menu={{
                        items,
                    }}
                    trigger={['click']}
                >
                    <a style={{ cursor: 'pointer' }}>
                        <ThreeDotsIcon />
                    </a>
                </Dropdown>
            </div>
            <div className='menuDiv'>
                <p>MENU</p>
                <div className={pathname === '/dashboard' ? 'navLink active' : 'navLink'} onClick={() => {
                    navigate('/dashboard')
                    if (onClose) {
                        onClose()
                    }
                }}>
                    <HomeIcon /> <p>Home</p>
                </div>
                <div className={pathname === '/dashboard/view-campaigns' ? 'navLink active' : 'navLink'} onClick={() => {
                    navigate('/dashboard/view-campaigns')
                    if (onClose) {
                        onClose()
                    }
                }}>
                    <DashboardIcon /> <p>View Campaigns</p>
                </div>
            </div>
            <button className='createCampaign' onClick={() => {
                navigate('/create-campaign/add-track')
                if (onClose) {
                    onClose()
                }
            }}>Create a campaign</button>
            <hr className='seprator' />

            <div className='menuDiv'>
                <p>ACTIONS</p>
                <div className={pathname === '/dashboard/account-overview' ? 'navLink active' : 'navLink'} onClick={() => {
                    navigate('/dashboard/account-overview')
                    if (onClose) {
                        onClose()
                    }
                }}>
                    <User color={'white'} /> <p>Account Overview</p>
                </div>
                <div className='navLink'>
                    <SettingIcon color="white" /> <p>Settings</p>
                </div>
            </div>
        </div>
    )
}

export default Sidebar