import React, { useEffect, useRef } from 'react'
import monetizationSidebar from './../../Assets/Images/monetizationSidebar.png'
import musicActive from './../../Assets/Images/music-campaign.svg'
import budgetActive from './../../Assets/Images/budget.svg'
import searchActive from './../../Assets/Images/search.svg'
import music from './../../Assets/Images/music-campaign.png'
import budget from './../../Assets/Images/budget.png'
import search from './../../Assets/Images/search.png'
import { useNavigate } from 'react-router-dom'



function CampaignSideBar({ tab }) {
    const navigate = useNavigate()
    useEffect(()=>{
        console.log(tab,'tabtab')
    },[tab])
    const scrollContainerRef = useRef(null);
    const activeTabRef = useRef(null);

    useEffect(() => {
        if (activeTabRef.current) {
            activeTabRef.current.scrollIntoView({
                behavior: 'smooth',
                inline: 'center',
            });
        }
    }, [tab]);
    return (
        <>
            <div className='CreateCampaignSideBar'>
                <div className='innerSideBar'>
                    <img className='monetizationSidebarBg' src={monetizationSidebar} />
                    <div className='stepCampaign'>
                        <div className={`step ${tab === 'add-track' ? 'active' : ''}`}
                        // onClick={()=>{
                        //     navigate('/create-campaign/add-track')
                        // }}
                        >
                            <div className='stepContentIamge'>
                                <img src={tab === 'add-track' ? musicActive : music} />
                            </div>
                            <div className='stepContentText'>
                                <h3>Add song details</h3>
                                <p><b>Step 01:</b> Add your song details</p>
                            </div>
                        </div>

                        <div className={`step ${tab === 'set-budget-duration' ? 'active' : ''}`}
                        // onClick={()=>{
                        //     navigate('/create-campaign/set-budget-duration')
                        // }}
                        >
                            <div className='stepContentIamge'>
                                <img src={tab === 'set-budget-duration' ? budgetActive : budget} />
                            </div>
                            <div className='stepContentText'>
                                <h3>Set budget and duration</h3>
                                <p><b>Step 02:</b> Add a budget and campaign duration according to your needs</p>
                            </div>
                        </div>

                        <div className={`step ${tab === 'review-campaign' ? 'active' : ''}`}
                        // onClick={()=>{
                        //     navigate('/create-campaign/review-campaign')
                        // }}
                        >
                            <div className='stepContentIamge'>
                                <img src={tab === 'review-campaign' ? searchActive : search} />
                            </div>
                            <div className='stepContentText'>
                                <h3>Review campaign</h3>
                                <p><b>Step 03:</b> Review and submit the details</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='createCampaignTopBar'>
            <div className='innerCreateCampaignTopBar' ref={scrollContainerRef} style={{ overflowX: 'auto', display: 'flex' }}>
                <div
                    className={`campaignTab ${tab === 'add-track' ? 'active' : ''}`}
                    ref={tab === 'add-track' ? activeTabRef : null}
                >
                    <div className='stepContentIamge'>
                        <img src={tab === 'add-track' ? musicActive : music} />
                    </div>
                    <div className='stepContentText'>
                        <h3>Add song details</h3>
                        <p><b>Step 01:</b> Add your song details</p>
                    </div>
                </div>
                <div
                    className={`campaignTab ${tab === 'set-budget-duration' ? 'active' : ''}`}
                    ref={tab === 'set-budget-duration' ? activeTabRef : null}
                >
                    <div className='stepContentIamge'>
                        <img src={tab === 'set-budget-duration' ? budgetActive : budget} />
                    </div>
                    <div className='stepContentText'>
                        <h3>Set budget and duration</h3>
                        <p><b>Step 02:</b> Add a budget and campaign duration according to your needs</p>
                    </div>
                </div>
                <div
                    className={`campaignTab ${tab === 'review-campaign' ? 'active' : ''}`}
                    ref={tab === 'review-campaign' ? activeTabRef : null}
                >
                    <div className='stepContentIamge'>
                        <img src={tab === 'review-campaign' ? searchActive : search} />
                    </div>
                    <div className='stepContentText'>
                        <h3>Review campaign</h3>
                        <p><b>Step 03:</b> Review and submit the details</p>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default CampaignSideBar