import React, { useEffect, useState } from "react";
import { EditIcon } from "../../../Assets/Icons";
import "./reviewCampaign.scss";
import { calculateStreamsForPrice, extractId, getParams } from "../../../Constant/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSongDetail } from "../../../module/features/Spotify";
import { addArtistCampaigns, getArtistCampaigns, getNotfication } from "../../../module/features/Artist";
import questionMark from "./../../../Assets/Images/question-mark.jpg";
import Spinner from "../../../Constant/Spinner";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { toast } from "react-toastify";
import CampaignSuccess from "./CampaignSuccess";
function ReviewCampaign() {
  let [data, setData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const track = useSelector((e) => e.spotifySlice.track);
  const user = useSelector(e => e.authenticationSlice.user)
  const auth0User = useSelector(e => e.authenticationSlice.auth0User)
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = (redirect) => {
    setIsModalOpen(false);
    navigate(redirect);
  };

  const handleCancel = () => {
    // setIsModalOpen(false);
    // navigate("/dashboard/view-campaigns");
  };


  useEffect(() => {
    console.log('runnnnnnnnnnnnnnnnnnnnnnnnnn')
    let param = getParams("data");
    console.log(param, "param");
    setData(param);
    dispatch(
      getSongDetail({
        q: extractId(param?.track_link),
      })
    );
  }, []);
  const [loaderCampaign, setLoaderCampaign] = useState(false)
  const [run, setRun] = useState(false)
  useEffect(() => {
    if (!run) {
      if (data && data?.track_name) {
        console.log(user, auth0User, 'useruser')
        setRun(true)
        const type = queryParams.get("type")
        if (type === "cancel") {
          toast.error("cancel payment");
        } else if (type === "success") {
          setLoaderCampaign(true)
          showModal()
          console.log(data, 'runnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn')
          dispatch(addArtistCampaigns({
            data: {
              ...data,
              artist_email: auth0User?.email || data?.artist_email || "",
              userId: auth0User?.userId || "",
              start_date: new Date(data?.start_date).getTime(),
            }, callback
          }));
        }
      }
    }

  }, [data])

  const callback = () => {
    setLoader(true);
    localStorage.removeItem("campaign-data");
    localStorage.setItem('notification', "true")
    dispatch(getNotfication())
    dispatch(getArtistCampaigns())
    setLoaderCampaign(false)
  };

  const addArtistCampaignsFn = async () => {
    console.log(user, 'useruser')
    localStorage.setItem("campaign-data", JSON.stringify(data));
    // if (!user || Object.keys(user || {}).length === 0) {
    setLoader(true)
    // } else {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PRIVATE_KEY);
    console.log("runnn");
    const budget = data?.budget; // retrieve the already set budget
    const email = auth0User?.email || data?.artist_email; // retrieve the already set budget
    const stripeSession = await createStripeSession(budget, email);
    if (stripe) {
      setLoader(false);
      stripe.redirectToCheckout({ sessionId: stripeSession.sessionId });
    }
    // }
  };

  const createStripeSession = async (budget, email) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URI}/artistCampaign/create-stripe-session`,
      {
        budget,
        email,
      }
    );
    return response.data;
  };

  const back = () => {
    localStorage.setItem("campaign-data", JSON.stringify(data));
    navigate(`/create-campaign/set-budget-duration`);
  };
  const isValid = () => {
    if (auth0User && auth0User?.email) {
      return false
    } else if (data?.artist_email) {
      return false
    } else {
      return true
    }
  }
  const loginFunction = () => {
    const domain = "dev-2z4p074m.us.auth0.com";
    const client_id = process.env.REACT_APP_AUTH0;
    const audience = "https://dev-2z4p074m.us.auth0.com/api/v2/";
    const scope = "openid profile email";
    const response_type = "token";
    // const budget = data?.budget;
    const redirect_uri = `${window.location.origin}/callback?redirect=/dashboard/view-campaigns`;
    window.location.replace(
      `https://${domain}/authorize?` +
      `audience=${audience}&` +
      `scope=${scope}&` +
      `response_type=${response_type}&` +
      `client_id=${client_id}&` +
      `redirect_uri=${redirect_uri}`
    );
  }
  return (
    <div className="ReviewCampaign">
      <div className="innerReviewCampaign">
        <div className="trackDetail">
          <div className="innerTrackDetail">
            <div className="heading">
              <div className="headingDiv">
                <h3>Spotify song link</h3>
                <a href={data?.spotify_link}>
                  <p>{data?.spotify_link}</p>
                </a>
              </div>
              <button
                className="editButon"
                onClick={() => {
                  navigate(`/create-campaign/add-track`);
                }}
              >
                <EditIcon />
              </button>
            </div>
            <div className="spotifyLink">
              <div className="songDetail">
                <div className="imageName">
                  <img src={track?.album?.images[0]?.url || questionMark} />
                  <div className="songNameArtist">
                    <p>{track?.name || data?.track_name}</p>
                    <p>{track?.artists[0]?.name || data?.artist_name}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="lyricDiv">
              <div className="innerLyricDiv">
                <h3>Lyrics of your track</h3>
                <p>{data?.track_lyrics?.join(", ")}</p>
                {/* <p>English</p> */}
              </div>
            </div>
            <div className="dateSchedule">
              <div className="innerDateSchedule">
                <div className="dateDiv">
                  <div className="date">
                    <p className="dateLabel">Start date</p>
                    <p className="dateTime">
                      {new Date(data?.start_date).toLocaleDateString()}
                    </p>
                  </div>
                  <p className="disclaimerStartDate">
                  We will start with the campaign as soon as possible. Our team will contact you within 2 business days.
                  </p>
                </div>
              </div>
            </div>
            <div className="budgetDiv">
              <div className="heading">
                <h3>Campaign budget</h3>
                <h2>
                  ${data?.budget}{" "}
                  {/* <span>{calculateStreamsForPrice(Number(data?.budget || 0)).text}</span> */}
                </h2>
              </div>
              <button
                className="editButon"
                onClick={() => {
                  navigate(`/create-campaign/set-budget-duration`);
                }}
              >
                <EditIcon />
              </button>
            </div>

            <div className="note">
              <p>Note: By submitting this campaign, you agree to our terms & conditions and your request will be sent to one of our representative. You will be contacted via email accordingly.
                In case of any questions or concerns, feel free to reach us out at support@songplace.io.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="stepsDiv">
        <p onClick={back}>Previous step</p>
        <button onClick={addArtistCampaignsFn} disabled={isValid()}>
          {loader ? (
            <>
              <Spinner />
            </>
          ) : (
            `Submit`
          )}
        </button>
      </div>
      <CampaignSuccess isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        loaderCampaign={loaderCampaign}
        loginFunction={loginFunction}
      />
    </div>
  );
}

export default ReviewCampaign;
