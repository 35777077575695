import React from 'react'
import LandingNavbar from '../../Components/LandingNavbar/LandingNavbar'
import './landingpage_final_updated.scss'
import calenderIcon from './../../Assets/Images/calender.png'
import CTP from './../../Assets/Images/ctp.svg'
import MFY from './../../Assets/Images/mfy.svg'
import FC from './../../Assets/Images/fc.svg'
import BYV from './../../Assets/Images/byv.svg'
import musicIcon from './../../Assets/Images/music.png'
import userIcon from './../../Assets/Images/users.png'
import musicCalenderIcon from './../../Assets/Images/musicCalender.png'
import scheduleIcon from './../../Assets/Images/schedule.png'
import searchUrlIcon from './../../Assets/Images/searchUrl.png'
import insightIcon from './../../Assets/Images/insight.png'
import AboutUsImage from './../../Assets/Images/aboutUsImage.png'
import Footer from '../../Components/Footer/Footer'
import Budget from '../../Components/Budget/Budget'
import { useNavigate } from 'react-router-dom'
import { useLottie } from "lottie-react";
import header from './../../Assets/Header.json'
import AboutUsLottie from './AboutUsLottie'
function LandingPage() {
    const navigate = useNavigate()

    const handleFormSubmit = async () => {
        const domain = "dev-2z4p074m.us.auth0.com";
        const client_id = process.env.REACT_APP_AUTH0;
        const audience = "https://dev-2z4p074m.us.auth0.com/api/v2/";
        const scope = "openid profile email";
        const response_type = "token";
        const redirect_uri = `${window.location.origin}/callback`;
        window.location.replace(
            `https://${domain}/authorize?` +
            `audience=${audience}&` +
            `scope=${scope}&` +
            `response_type=${response_type}&` +
            `client_id=${client_id}&` +
            `redirect_uri=${redirect_uri}`
        );
    };
    const options = {
        animationData: header,
        loop: true,
    };
    const { View } = useLottie(options);

    return (
        <div className='LandingPageBody'>
            <LandingNavbar />
            <div className='LandingPage'>
                <div className='innerLandingPage'>
                    <div className='heroSection' id="heroSection">
                        <div className='innerHeroSection'>
                            <div className='heroSectionText'>
                                {/* <span className='getYourSong'>Get your song placed!</span> */}
                                <h1>
                                    The best <br className='hidePc' />  <span>organic music <br className='hidePc' /> promotion</span> in the music industry
                                </h1>

                                <p>
                                    Join Songplace and supercharge your music career! Pitch for organic playlist placements (100% bot-free), get in front of the right audience with the best Spotify promotion and catch the attention of Spotify’s algorithm for potential algorithmic and editorial placements - all while growing a loyal fanbase.
                                </p>
                                <div className='startCampaignSignup'>
                                    <button className='campaign' onClick={() => {
                                        navigate('/create-campaign/add-track')
                                    }}>Start a campaign</button>
                                    <button className='signUp' onClick={handleFormSubmit}><span>Sign up</span></button>
                                </div>
                            </div>
                            <div className='heroImage'>
                                <div className='heroImageLottie'>
                                    {View}
                                </div>
                            </div>
                        </div>
                        {/* <div className='bgGrad'></div> */}
                    </div>
                    <div className='budgetSection' id="budgetSection">
                        {/* <div className='bgGrad last'></div> */}
                        <div className='innerBudgetSection'>
                            <h3>Customized <span>Budgeting</span></h3>
                            <p>Every artist deserves a chance. We offer easy and customized<br className='hideMob' />budgeting option tailored to your needs.</p>
                            <Budget />
                        </div>
                    </div>
                    <div className='featuresSection' id="featuresSection">
                        <div className='innerFeaturesSection'>
                            <h3>Features</h3>
                            <p className='headingText'>We offer amazing features to get you more visibility <br className='hideMob' />across top curators and playlists.</p>
                            <div className='featuresDiv'>
                                <div className='featDiv'>
                                    <div className="innerFeatDiv">
                                        <img src={CTP} />
                                        <h5>Connect with top playlists</h5>
                                        <p>
                                            Find curators and playlists that match your vibe.
                                        </p>
                                    </div>
                                </div>
                                <div className='featDiv'>
                                    <div className="innerFeatDiv">
                                        <img src={FC} />
                                        <h5>Flexible campaigns</h5>
                                        <p>
                                            Set your budget and scale your streams.
                                        </p>
                                    </div>
                                </div>
                                <div className='featDiv'>
                                    <div className="innerFeatDiv">
                                        <img src={BYV} />
                                        <h5>Boost your visibility</h5>
                                        <p>
                                            Get featured where it matters the most.
                                        </p>
                                    </div>
                                </div>
                                <div className='featDiv'>
                                    <div className="innerFeatDiv">
                                        <img src={musicCalenderIcon} />
                                        <h5>Create your profile</h5>
                                        <p>
                                            Showcase your music and track your success.
                                        </p>
                                    </div>
                                </div>
                                <div className='featDiv'>
                                    <div className="innerFeatDiv">
                                        <img src={MFY} />
                                        <h5>Made for you</h5>
                                        <p>
                                            Personalized options for every artist's goals.
                                        </p>
                                    </div>
                                </div>
                                <div className='featDiv'>
                                    <div className="innerFeatDiv">
                                        <img src={searchUrlIcon} />
                                        <h5>Streamlined submissions</h5>
                                        <p>
                                            Upload your song and details in minutes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='aboutUsSection' id="aboutUsSection">
                        {/* <div className='bgGrad'></div> */}
                        <div className='innerAboutUsSection'>
                            <div className='aboutUsImg'>
                                <div className='lottieDiv'>
                                    <AboutUsLottie />
                                </div>
                            </div>
                            <div className='aboutUsContent'>
                                <h3>About Us</h3>
                                <p>At Songplace, we’re on a mission to help artists like you grow their careers through authentic, organic playlist placements. In an industry full of shortcuts and gimmicks, we believe in the power of real connections and organic discovery. We don’t just get your music on playlists; we connect you with curators who truly resonate with your sound, helping you reach the right audience and build a loyal fanbase.
                                    <br />
                                    <br />Whether you're an emerging artist, record label or a seasoned pro, Songplace is here to amplify your voice and make sure your music is heard by the people who matter. No fake streams, no inflated numbers — just real growth, one playlist at a time.
                                    <br />
                                    <br />Join us at Songplace and let’s take your music where it belongs — into the hearts and playlists of fans who are ready to discover you.</p>
                                <button className='campaignStartBtn' onClick={() => {
                                    navigate('/create-campaign/add-track')
                                }}>Start a campaign</button>
                            </div>
                        </div>
                    </div>
                    <form
                        action="https://spotplace.us14.list-manage.com/subscribe/post?u=5d845dc9bdd50ddec46ce928b&amp;id=9fe6ded621"
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        className="validate"
                        target="_blank"
                    // novalidate
                    >
                        <div className='newsLetterSection' id="newsLetterSection">
                            <div className='innerNewsLetterSection'>
                                <h3>Join Our Newsletter</h3>
                                <p>Join our newsletter to get exclusive benefits on <br className='hideMob' /> new promotions and access to popular playlists.</p>
                                <div className='newsLetterInput'>
                                    <input type="email" name="EMAIL"
                                        className="required email"
                                        id="mce-EMAIL" placeholder='Enter your email address' required />
                                    <button className='submitNewsLetter' type="submit"
                                        // onClick={handleCancel}
                                        value="Subscribe"
                                        name="subscribe"
                                        id="mc-embedded-subscribe">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default LandingPage