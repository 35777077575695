import React, { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useNavigate
} from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';
import Authentication from './Authentication';
import Login from './Pages/Auth/Login';
import Home from './Pages/Home/Home';
import Sidebar from './Components/Sidebar/Sidebar';
import Navbar from './Components/Navbar/Navbar';
import AddTrack from './Pages/Create-Campaign/AddTrack/AddTrack';
import CampaignSideBar from './Components/Sidebar/CampaignSideBar';
import SetBudget from './Pages/Create-Campaign/AddTrack/SetBudget';
import { useLocation } from 'react-router-dom';
import ReviewCampaign from './Pages/Create-Campaign/AddTrack/ReviewCampaign';
import ViewCampaign from './Pages/view-campaign/ViewCampaign';
import AccountOverview from './Pages/AccountOverview/AccountOverview';
import Callback from './Pages/Callback/Callback';
import { getProfile } from './module/features/Authentication';
import { getToken } from './module/features/Spotify';
import CompleteProfile from './Pages/CompleteProfile/CompleteProfile';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getArtistCampaigns } from './module/features/Artist';
import LandingPage from './Pages/LandingPage/LandingPage';
import { DrawerOpenIcon } from './Assets/Icons';
import { Drawer } from 'antd';
import PrivacyPolicy from './Pages/policies/privacy-policy';
import Condition from './Pages/policies/terms-and-condition';
const LayoutOutlet = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(e => e.authenticationSlice.user)
  const [open, setOpen] = useState(false)
  const onClose = () => { setOpen(false) }
  useEffect(() => {
    dispatch(getToken())
  }, [])
  useEffect(() => {
    if (user && !user?.name) {
      navigate('/dashboard/complete-profile')
    }
    console.log(user, 'useruseruser')
  }, [user])
  return (
    <>
      <div className='innerLayout'>
        <div className='layoutBody'>
          <div className='sideBar'>
            <Sidebar />
          </div>
          <div className='content'>
            <div className='navbarDiv'>
              <button className='drawerOpenBtn' onClick={() => setOpen(true)}>
                <DrawerOpenIcon />
              </button>
              <Navbar hideLogo={true} />
              <Drawer
                title={false}
                placement={'left'}
                closable={false}
                onClose={onClose}
                open={open}
                key={'left'}
                className='sideBarDrawer'
                rootClassName='sideBarDrawerRoot'

              >
                <Sidebar onClose={onClose} />
              </Drawer>
            </div>
            <div className='innerContent'>
              <Authentication>
                <Outlet />
              </Authentication>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

const CreateCampaignOutlet = () => {
  const location = useLocation();
  const [tab, setTab] = useState('add-track');
  const user = useSelector(e => e.authenticationSlice.user)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getToken())
  }, [])
  useEffect(() => {
    if (location.pathname.includes('/create-campaign/')) {
      const tabName = location.pathname.split('/')[2];
      setTab(tabName);
    }
  }, [location.pathname])
  return (
    <>
      <div className='innerLayout'>

        <Navbar hideLogo={false} />

        <div className='layoutBody campmaignLayoutBody'>
          {/* <div className='sideBar'> */}

          <CampaignSideBar tab={tab} />
          {/* </div> */}
          <div className='content content-compaign'>
            <div className='innerContent'>
              <Authentication>
                <Outlet />
              </Authentication>
            </div>
          </div>
        </div>

      </div>

    </>
  )
}

function LayoutComp() {
  const dispatch = useDispatch()
  const accessToken = useSelector(e => e.authenticationSlice.access_token)
  const auth0User = useSelector(e => e.authenticationSlice.auth0User)
  const user = useSelector(e => e.authenticationSlice.user)
  const navigateToLogout = () => {
    if (window.location.pathname.toLowerCase() != "/login"
    ) {
      window.location.href = "/"
    }
  }
  useEffect(() => {
    if (user) {
      dispatch(getArtistCampaigns())
    }
  }, [user])
  useEffect(() => {
    dispatch(getProfile())
    // if (user) {
    //   if (window.location.pathname === "/login" || window.location.pathname === '/register') {
    //     window.location.href = "/dashboard"
    //   }
    // }
  }, [])



  useEffect(() => {
    // if (accessToken, auth0User) {
    //   if (window.location.pathname === "/") {
    //     window.location.href = "/dashboard"
    //   }
    // }
    console.log(user, 'useruser')
  }, [])
  const router = createBrowserRouter([
    {
      path: "/",
      Component: LandingPage
    },
    {
      path: "/privacy-policy",
      Component: PrivacyPolicy
    },
    {
      path: "/terms-and-condition",
      Component: Condition
    },
    {
      path: "/callback",
      Component: Callback
    },

    {
      element: <LayoutOutlet />,
      children: [
        {
          path: "/dashboard",
          Component: Home
        },
        {
          path: "/dashboard/view-campaigns",
          Component: ViewCampaign
        },
        {
          path: "/dashboard/account-overview",
          Component: AccountOverview
        },
        {
          path: "/dashboard/complete-profile",
          Component: CompleteProfile
        },


      ]
    },
    {
      element: <CreateCampaignOutlet />,
      children: [
        {
          path: "/create-campaign/add-track",
          Component: AddTrack
        },
        {
          path: "/create-campaign/set-budget-duration",
          Component: SetBudget
        },
        {
          path: "/create-campaign/review-campaign",
          Component: ReviewCampaign
        }
      ]
    }

  ]);



  return (
    <div className='layout'>

      <RouterProvider router={router} />
      <ToastContainer />
    </div>
  )
}

export default LayoutComp
