import React, { useEffect } from 'react'
import './viewCampaign.scss'
import Campaign from '../../Components/Campaign/Campaign'
import { useDispatch, useSelector } from 'react-redux'
import { getArtistCampaigns } from '../../module/features/Artist'
import { getGreeting } from '../../Constant/Utils'

function ViewCampaign() {
    const auth0User = useSelector(e => e.authenticationSlice.auth0User)
    const campaigns = useSelector(e => e.artistSlice.campaigns)
    const user = useSelector(e => e.authenticationSlice.user)
    const dispatch = useDispatch()

    useEffect(() => {
        console.log(campaigns)
    }, [campaigns])
    return (
        <div className='ViewCampaign'>
            <div className='innerViewCampaign'>
                <div className='headingSearch'>
                    {/* <h2>{getGreeting()} {user?.name}!</h2> */}
                    <h2>Artist Dashboard</h2>
                    {/* <label className='searchCurators'>
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M11.7204 11.7772L14.9889 15.6127L14.422 16.0999L11.1446 12.2645C10.0167 13.1207 8.74705 13.5488 7.33569 13.5488C6.47942 13.5488 5.66008 13.382 4.87766 13.0484C4.09524 12.7147 3.42204 12.2659 2.85807 11.702C2.2941 11.138 1.8453 10.4648 1.51169 9.68239C1.17807 8.89995 1.01125 8.0806 1.01123 7.22436C1.01121 6.36811 1.17803 5.54877 1.51169 4.76633C1.84535 3.98388 2.29414 3.31069 2.85807 2.74674C3.42199 2.18279 4.09519 1.734 4.87766 1.40036C5.66012 1.06672 6.47947 0.899902 7.33569 0.899902C8.19191 0.899902 9.01125 1.06672 9.79372 1.40036C10.5762 1.734 11.2494 2.18279 11.8133 2.74674C12.3772 3.31069 12.826 3.98388 13.1597 4.76633C13.4934 5.54877 13.6602 6.36811 13.6601 7.22436C13.6601 8.10423 13.4889 8.93686 13.1464 9.72226C12.8039 10.5077 12.3285 11.1927 11.7203 11.7773L11.7204 11.7772ZM7.33576 12.8047C8.34553 12.8047 9.27856 12.5552 10.1348 12.0562C10.9911 11.5572 11.6687 10.8796 12.1677 10.0234C12.6667 9.16711 12.9162 8.23409 12.9162 7.22429C12.9162 6.21449 12.6667 5.28147 12.1677 4.42522C11.6687 3.56898 10.991 2.89137 10.1348 2.39238C9.2786 1.8934 8.34558 1.64391 7.33576 1.64391C6.32594 1.64391 5.39291 1.8934 4.53669 2.39238C3.68047 2.89137 3.00286 3.56898 2.50385 4.42522C2.00485 5.28147 1.75536 6.21449 1.75538 7.22429C1.7554 8.23409 2.00489 9.16711 2.50385 10.0234C3.00281 10.8796 3.68042 11.5572 4.53669 12.0562C5.39296 12.5552 6.32598 12.8047 7.33576 12.8047Z" fill="#242429" />
                        </svg>

                        <input placeholder='Find curators' />
                    </label> */}
                </div>
                <div className='campaignDiv'>
                    {campaigns?.map((a, i) => (
                        <Campaign data={a} index={i} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ViewCampaign