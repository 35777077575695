
import Footer from '../../Components/Footer/Footer';
import LandingNavbar from '../../Components/LandingNavbar/LandingNavbar';
import './policies.scss'
const PrivacyPolicy = () => {
    return (
        <div className='LandingPageBody'>

            <LandingNavbar />

            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    // height: "450px",
                    // overflow: "auto",
                }}
                className='LandingPage'
            >
                <div className="justifywidth">
                    <div style={{ marginTop: "30px" }}>
                        <h3
                            className="PrivacyPolicyHeading"
                        >
                            GDPR Privacy Policy
                        </h3>
                        <br />
                        <p className="PrivacyPolicyParam">
                            Songplace.io gathers and processes your personal information in
                            accordance with this privacy notice and in compliance with the
                            relevant data protection Regulation and laws. This notice provides
                            you with the necessary information regarding your rights and our
                            and explains how, why and when we process your personal data. This
                            policy is relevant to your use of the (“Company”) website and your
                            relationship with the Company. This document also documents our
                            data protection policy and the rights of individuals that the
                            Company engages with (“Data Subjects”) in respect of personal data
                            under the General Data Protection Regulation (“Regulation”). A
                            ‘Data Subject’ may be an individual or an individual acting on
                            behalf of a body corporate (i.e. a company director).{" "}
                        </p>
                        <p className="PrivacyPolicyParam">
                            {" "}
                            Please read them carefully as they affect your rights and
                            liabilities under the law. If you do not agree to this Privacy
                            Policy, please do not use the Company website or engage with us.
                            If you have any questions on the Terms and Conditions, please
                            contact us. If you are seeking to instruct the Company for the
                            provision of recruitment services, Our standard terms and
                            conditions shall apply.
                        </p>
                    </div>
                    <div style={{ marginTop: "50px" }}>
                        <h3
                            className="PrivacyPolicyHeading"
                        >
                            GDPR Privacy Policy
                        </h3>
                        <br />
                        <p className="PrivacyPolicyParam">
                            The General Data Protection Regulations defines “personal data” as
                            any information relating to an identified or identifiable natural
                            person (a data subject); an identifiable natural person is one who
                            can be identified, directly or indirectly, in particular by
                            reference to an identifier such as a name, an identification
                            number, location data, an online identifier, or to one or more
                            factors specific to the physical, physiological, genetic, mental,
                            economic, cultural, or social identity of that natural person.
                        </p>{" "}
                        <p className="PrivacyPolicyParam">
                            This Policy sets out the procedures that are to be followed when
                            dealing with personal data. The procedures and principles set out
                            herein must be followed at all times by the Company, its
                            employees, agents, contractors, or other parties working on behalf
                            of the Company.
                        </p>
                    </div>

                    <div style={{ marginTop: "50px" }}>
                        <h3
                            className="PrivacyPolicyHeading"
                        >
                            General Data Protection Regulation (GDPR)
                        </h3>
                        <br />
                        <p className="PrivacyPolicyParam">
                            The Company may collect your personal details including, but not
                            limited to, your name and contact details including your e-mail
                            address and any other relevant information with your express
                            permission. This information is held, used and disclosed by us in
                            the following ways until permission is withdrawn:
                        </p>
                        <br />
                        <ul className="PrivacyPolicyParam">
                            <li>To continue to provide our services to you. </li>
                            <li>
                                To maintain our business relationship throughout the lifetime of
                                that relationship, where you are a client or user of our
                                website, services.{" "}
                            </li>
                            <li>To enable us to answer any queries you may have. </li>
                            <li>
                                To market our products and services to you; to keep you updated
                                on any relevant industry news; to update you on any events,
                                promotions and competitions, reports and any other information
                                we feel may be relevant or of interest to you. You retain the
                                right to unsubscribe from such communications at any time.{" "}
                            </li>
                            <li>To contractual obligations with you. </li>
                            <li>
                                To enable the development and marketing of other products and
                                services by.{" "}
                            </li>
                            <li>
                                To enable the development and marketing of other products and
                                services by.{" "}
                            </li>
                            <li>
                                To continually improve our customer service efforts and to make
                                our services more valuable to you.{" "}
                            </li>
                            <li>
                                The Company may also release personal information to regulatory
                                or law enforcement if they require us to do so.{" "}
                            </li>
                            <li>
                                We will also only disclose your information where we are
                                permitted and requested to do so by law.{" "}
                            </li>
                            <li>
                                The Company will also seek your consent to collect, hold, use
                                and disclose your personal information for any other purpose not
                                listed above, but will continue to do so only if your permission
                                is given and from which you understand you can withdraw
                                permission.{" "}
                            </li>
                            <li>
                                If we are holding historic data about you or your company that
                                no longer is of use or has any significance it will be deleted
                                unless you have given consent, or there is some kind of legal
                                obligation to retain it.{" "}
                            </li>
                            <li>
                                Only members of the Company staff that need to have access to
                                your information to carry out their normal duties will be
                                allowed access to the information.{" "}
                            </li>
                            <li>
                                We have appointed a representative to oversee the application of
                                our privacy policy.{" "}
                            </li>
                            <li>
                                That representative is and any concerns over the retention, use
                                or disclosure of your information should be addressed to them
                                via our contact page.{" "}
                            </li>
                        </ul>
                    </div>
                    <div style={{ marginTop: "50px" }}>
                        <h3
                            className="PrivacyPolicyHeading"
                        >
                            How long do we hold personal data?
                        </h3>
                        <br />
                        <p className="PrivacyPolicyParam">
                            We retain personal data for as long as a candidate is active and
                            for 1 year unless required to retain for a longer period by EU
                            law; if this is the case we will only retain your basic personal
                            data (name, address and contact information).
                        </p>{" "}
                    </div>
                    <div style={{ marginTop: "50px" }}>
                        <h3
                            className="PrivacyPolicyHeading"
                        >
                            Data Processing for Specific Purposes Only
                        </h3>
                        <br />
                        <p className="PrivacyPolicyParam">
                            The Company collects and processes the personal data set out in
                            this Policy. This may include personal data received directly from
                            Data Subjects (for example, contact details used when a data
                            subject communicates with us).
                        </p>
                        <p className="PrivacyPolicyParam">
                            The Company only processes personal data for the specific purposes
                            set out in this Policy (or for other purposes expressly permitted
                            by the Regulation). The purposes for which we process personal
                            data will be informed to Data Subjects at the time that their
                            personal data is collected, where it is collected directly from
                            them, or as soon as possible (not more than one calendar month)
                            after collection where it is obtained from a third party.
                        </p>
                        <p className="PrivacyPolicyParam">
                            The Company will only collect and process personal data for and to
                            the extent necessary for the specific purpose(s) informed to Data
                            Subjects. The Company shall ensure that all personal data
                            collected and processed is kept accurate and up-to-date, so far as
                            reasonably practicable. Where any inaccurate or out-of-date data
                            is found, all steps will be taken without delay to amend or erase
                            that data, so far as reasonably practicable.
                        </p>
                        <p className="PrivacyPolicyParam">
                            The Company shall ensure that all personal data collected and
                            processed is kept secure and protected against or unlawful
                            processing and against accidental loss, destruction or damage.{" "}
                        </p>
                        <p className="PrivacyPolicyParam">
                            Data Subjects may request that the Company ceases processing the
                            personal data it holds about them. If a data subject makes such a
                            request, the Company shall retain only the amount of personal data
                            pertaining to that data subject that is necessary to ensure that
                            no further processing of their personal data takes place.
                        </p>{" "}
                    </div>
                    <div style={{ marginTop: "50px" }}>
                        <h3
                            className="PrivacyPolicyHeading"
                        >
                            Your Right to Access
                        </h3>
                        <br />
                        <p className="PrivacyPolicyParam">
                            The law states that you have the right at any time to ask us to
                            share with you the information that we hold that you have supplied
                            to us. If you make such a request we will ask you to verify your
                            identity and possibly ask you to give us more information about
                            such a request. We may need to charge a nominal fee for this
                            service for administrative purposes. We will only refuse your
                            request if we are legally permitted or required to do so. If this
                            is the case then we will give you reasons for doing so. To make a
                            request for information, please contact us.
                        </p>{" "}
                        <p className="PrivacyPolicyParam">
                            Please be aware that you also have the right to ask the Company to
                            stop using the information that you supplied us. Under the right
                            to erasure you can also request for deletion of your file,
                            although you should be aware that in some circumstances we may not
                            be required or able to do so, particularly where your file also
                            holds information about our clients or financial information that
                            we need to keep for periods of up to six years; i.e. which relate
                            to tax matters. Under any circumstances, we cannot comply with
                            your request for information. We will provide a full explanation
                            as to why this is so.
                        </p>{" "}
                    </div>
                    <div style={{ marginTop: "50px" }}>
                        <h3
                            className="PrivacyPolicyHeading"
                        >
                            Deleting of Your Data
                        </h3>
                        <br />
                        <p className="PrivacyPolicyParam">
                            Data Subjects may request that the Company erases the personal
                            data it holds about them in the following circumstances:
                        </p>
                        <br />
                        <ul className="PrivacyPolicyParam">
                            <li>
                                It is no longer necessary for the Company to hold that personal
                                data with respect to the purpose for which it was originally
                                collected or processed;{" "}
                            </li>
                            <li>
                                The data subject wishes to withdraw their consent to the Company
                                holding and processing their personal data;{" "}
                            </li>
                            <li>
                                The data subject objects to the Company holding and processing
                                their personal data (and there is no overriding legitimate
                                interest to allow the Company to continue doing so).{" "}
                            </li>
                            <li>The personal data has been processed unlawfully;</li>
                            <li>
                                The personal data needs to be erased in order for the Company to
                                comply with a particular legal obligation.{" "}
                            </li>
                        </ul>
                        <br />
                        <p className="PrivacyPolicyParam">
                            Unless the Company has reasonable grounds to refuse to erase
                            personal data, all requests for erasure shall be complied with,
                            and the data subject informed of the erasure, within one month of
                            receipt of the data subject’s request (this can be extended by up
                            to two months in the case of complex requests, and in such cases
                            the data subject shall be informed of the need for the extension).
                        </p>
                    </div>
                    <div style={{ marginTop: "50px" }}>
                        <h3
                            className="PrivacyPolicyHeading"
                        >
                            Right to Suspend or Cancel Your Registration
                        </h3>
                        <br />
                        <p className="PrivacyPolicyParam">
                            We may suspend or cancel your registration immediately at our
                            reasonable discretion or if you breach any of your obligations
                            under these Terms and Conditions. You can cancel your registration
                            at any time by logging onto the Company website through the
                            “unsubscribe” link. The suspension or cancellation of your
                            registration and your right to use the Company website shall not
                            affect either party’s statutory rights or liabilities.
                        </p>{" "}
                        <p className="PrivacyPolicyParam">
                            Data Subjects have the right to object to the Company processing
                            their personal data based on legitimate interests (including
                            profiling), direct marketing (including profiling). Where a data
                            subject objects to the Company processing their personal data
                            based on its legitimate interests, the Company shall cease such
                            processing forthwith, unless it canbe demonstrated that the
                            Company’s legitimate grounds for such processing override the data
                            subject’s interests, rights freedoms; or the processing is
                            necessary for the conduct of legal claims.
                        </p>{" "}
                        <p className="PrivacyPolicyParam">
                            Where a data subject objects to the Company processing their
                            personal data for direct marketing purposes, the Company shall
                            cease such processing forthwith. Where a data subject objects to
                            the Company processing their personal data for scientific and/or
                            historical research and purposes, the data subject must, under the
                            Regulation, ‘demonstrate grounds relating to his or her particular
                            situation’. The Company is not required to comply if the research
                            is necessary for the performance of a task carried out for reasons
                            of public interest.
                        </p>{" "}
                    </div>
                    <div style={{ marginTop: "50px" }}>
                        <h3
                            className="PrivacyPolicyHeading"
                        >
                            Notification of a Data Breach
                        </h3>
                        <br />
                        <p className="PrivacyPolicyParam">
                            If a personal data breach occurs and that breach is likely to
                            result in a risk to the rights and freedoms of Data Subjects (e.g.
                            financial loss, breach of confidentiality, discrimination,
                            reputational damage, or other significant social or economic
                            damage), the data protection officer must ensure that the
                            Information Commissioner’s Office is informed of the breach
                            without delay, and in any event, within 72 hours after having
                            become aware of it.
                        </p>
                        <p className="PrivacyPolicyParam">
                            In the event that a personal data breach is likely to result in a
                            high risk to the rights and freedoms of Data Subjects, the data
                            protection officer must ensure that all affected Data Subjects are
                            informed of the breach directly and without undue delay. Data
                            breach notifications shall include the following information:
                        </p>
                        <ul className="PrivacyPolicyParam">
                            <li>
                                The categories and approximate number of Data Subjects
                                concerned;
                            </li>
                            <li>
                                The categories and approximate number of personal data records
                                concerned; The name and contact details of the Company’s data
                                protection officer (or where more information can be obtained);
                            </li>
                            <li>The likely consequences of the breach;</li>
                            <li>
                                Details of the measures proposed to be taken by the Company to
                                address the breach including, where appropriate, measures to
                                mitigate its possible adverse effects.
                            </li>
                        </ul>
                    </div>
                    <div style={{ marginTop: "50px" }}>
                        <h3
                            className="PrivacyPolicyHeading"
                        >
                            Data Protection Principles
                        </h3>
                        <br />
                        <p className="PrivacyPolicyParam">
                            This Policy aims to ensure compliance with the Regulation. The
                            Regulation sets out the following principles with which any party
                            handling personal data must comply. All personal data must be:
                        </p>
                        <p className="PrivacyPolicyParam">
                            In the event that a personal data breach is likely to result in a
                            high risk to the rights and freedoms of Data Subjects, the data
                            protection officer must ensure that all affected Data Subjects are
                            informed of the breach directly and without undue delay. Data
                            breach notifications shall include the following information:
                        </p>

                        <ul className="PrivacyPolicyParam">
                            <li>
                                Processed lawfully, fairly, and in a transparent manner in
                                relation to the data subject;
                            </li>
                            <li>
                                Collected for specified, explicit, and legitimate purposes and
                                not further processed in a manner that is incompatible with
                                those purposes; further processing for archiving purposes in the
                                public interest, scientific or historical research purposes or
                                statistical purposes shall not be considered to be incompatible
                                with the initial purposes;
                            </li>
                            <li>
                                Adequate, relevant and limited to what is necessary relation to
                                the purposes for which it is processed;
                            </li>
                            <li>
                                Accurate and, where necessary, kept up to date; every reasonable
                                step must be taken to ensure that personal data that is
                                inaccurate, having regard to the purposes for which they are
                                processed, is erased or rectified without delay;
                            </li>
                            <li>
                                Kept in a form which permits identification of Data Subjects for
                                no longer than is necessary for the purposes for which the
                                personal data is processed; personal data may be stored for
                                longer periods insofar as the personal data will be processed
                                solely for archiving purposes in the public interest, scientific
                                or historical research purposes or statistical purposes subject
                                to implementation of the appropriate technical and
                                organisational measures required by the Regulation in order to
                                safeguard the rights and freedoms of the data subject;
                            </li>
                            <li>
                                Processed in a manner that ensures appropriate security of the
                                personal data, including protection against or unlawful
                                processing and against accidental loss, destruction or damage,
                                using appropriate technical or measures.
                            </li>
                        </ul>
                    </div>
                    <div style={{ marginTop: "50px" }}>
                        <h3
                            className="PrivacyPolicyHeading"
                        >
                            Lawful, Fair and Transparent Data Processing
                        </h3>
                        <br />
                        <p className="PrivacyPolicyParam">
                            The Regulation seeks to ensure that personal data is processed
                            lawfully, fairly, and transparently, without adversely affecting
                            the rights of the data subject. The Regulation states that
                            processing of personal data shall be lawful if at least one of the
                            following applies:
                        </p>

                        <ul className="PrivacyPolicyParam">
                            <li>
                                The data subject has given consent to the processing of his or
                                her personal data for one or more specific purposes;
                            </li>
                            <li>
                                Processing is necessary for the performance of a contract to
                                which the data subject is a party or in order to take steps at
                                the request of the data subject prior to entering into a
                                contract;
                            </li>
                            <li>
                                Processing is necessary for compliance with a legal obligation
                                to which the controller is subject;
                            </li>
                            <li>
                                Processing is necessary to protect the vital interests of the
                                data subject or of another natural person;
                            </li>
                            <li>
                                Processing is necessary for the performance of a task carried
                                out in the public interest or in the exercise of official
                                authority vested in the controller;
                            </li>
                            <li>
                                Processing is necessary for the purposes of the legitimate
                                interests pursued by the controller or by a third party, except
                                where such interests are overridden by the fundamental rights
                                and freedoms of the data subject which require protection of
                                personal data, in particular where the data subject is a child.
                            </li>
                        </ul>
                    </div>
                    <div style={{ marginTop: "50px" }}>
                        <h3
                            className="PrivacyPolicyHeading"
                        >
                            Our Obligations
                        </h3>
                        <br />
                        <p className="PrivacyPolicyParam">
                            The Company shall ensure that the following measures are taken
                            with respect to the collection, holding, and processing of
                            personal data: All employees, agents, contractors, or other
                            parties working on behalf of the Company shall be made fully aware
                            of both their individual responsibilities and the Company’s
                            responsibilities under the Regulation and under this Policy, and
                            shall be provided with a copy of this Policy;
                        </p>
                        <p className="PrivacyPolicyParam">
                            Only employees, agents, sub-contractors, or other parties working
                            on behalf of the Company that need access to, and use of, personal
                            data in order to carry out their assigned duties correctly shall
                            have access to personal data held by the Company; All employees,
                            agents, contractors, or other parties working on behalf of the
                            Company handling personal data will be appropriately trained to do
                            so;
                        </p>
                        <p className="PrivacyPolicyParam">
                            All employees, agents, contractors, or other parties working on
                            behalf of the Company handling personal data will be appropriately
                            supervised;
                        </p>
                        <p className="PrivacyPolicyParam">
                            The performance of those employees, agents, contractors, or other
                            parties working on behalf of the Company handling personal data
                            shall be regularly evaluated and reviewed;
                        </p>
                        <p className="PrivacyPolicyParam">
                            All employees, agents, contractors, or other parties working on
                            behalf of the Company handling personal data will be bound to do
                            so in accordance with the principles of the Regulation and this
                            Policy by contract;
                        </p>
                    </div>
                    <div style={{ marginTop: "50px" }}>
                        <h3
                            className="PrivacyPolicyHeading"
                        >
                            Registering
                        </h3>
                        <br />
                        <p className="PrivacyPolicyParam">
                            By registering on our website, the registrants agree to our Terms
                            of Service & Privacy Policy as well as sharing their information
                            with relevant entities. The registrants understand that they can
                            opt-out at any time by contacting us at info@songplace.io.
                        </p>
                    </div>
                    <div style={{ marginTop: "50px" }}>
                        <h3
                            className="PrivacyPolicyHeading"
                        >
                            Amendments
                        </h3>
                        <br />
                        <p className="PrivacyPolicyParam">
                            We may update our GDPR Privacy Policy from time to time for legal
                            or regulatory reasons or to allow the proper operation of the
                            Company website. We will make reasonable efforts to notify you of
                            any changes. The changes will apply to the use of the Company
                            website after we have given notice.
                        </p>
                    </div>
                    <div style={{ marginTop: "50px" }}>
                        <h3
                            className="PrivacyPolicyHeading"
                        >
                            Amendments
                        </h3>
                        <br />
                        <p className="PrivacyPolicyParam">
                            We may update our GDPR Privacy Policy from time to time for legal
                            or regulatory reasons or to allow the proper operation of the
                            Company website. We will make reasonable efforts to notify you of
                            any changes. The changes will apply to the use of the Company
                            website after we have given notice.
                        </p>
                        <p className="PrivacyPolicyParam">
                            If you do not wish to accept the new Terms and Conditions you
                            should not continue to use the Company website. If you continue to
                            use the Company website after the date on which the change comes
                            into effect, your use of the Company website indicates your
                            agreement to be bound by the new Terms and Conditions.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />

        </div>
    );
};

export default PrivacyPolicy;
