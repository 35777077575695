import React, { useEffect } from 'react'
import './callback.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { addArtist, getProfile } from '../../module/features/Authentication';
import { loadStripe } from '@stripe/stripe-js';
import { getArtistCampaigns } from '../../module/features/Artist';
function Callback() {
    const accessToken = useSelector(e => e.authenticationSlice.access_token)
    const auth0User = useSelector(e => e.authenticationSlice.auth0User)
    const dispatch = useDispatch()
    const access_token = queryString.parse(useLocation().hash);
    const user = useSelector(e => e.authenticationSlice.user)
    const campaigns = useSelector(e => e.artistSlice.campaigns)
    useEffect(() => {
        dispatch(getArtistCampaigns())
    }, [])
    const navigate = useNavigate()
    const navigateToDashboard = (user_data) => {
        console.log(user_data)
        let url = new URL(window.location)
        let budget = url.searchParams.get('budget')
        // if(user_data && user_data?.name){
        console.log(budget, 'budgetbudget')
        if (budget) {
            stripFunction()
            // navigate(budget)
        } else {
            navigate(`/dashboard`)
        }
        // }else{
        //     navigate('/dashboard/complete-profile')
        // }
    }
    const callback = () => {
        dispatch(getProfile({ navigateToDashboard }))
    }
    useEffect(() => {
        if (access_token?.access_token) {
            fetch(`https://dev-2z4p074m.us.auth0.com/userinfo`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${access_token?.access_token}`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    console.log(res, 'resresres')
                    const bodies = {
                        client_id: process.env.REACT_APP_AUTH0,
                        client_secret: process.env.REACT_APP_AUTH0_SECRET,
                        audience: "https://dev-2z4p074m.us.auth0.com/api/v2/",
                        grant_type: `client_credentials`,
                    };
                    const congifs = {
                        headers: {
                            "content-type": "application/json",
                        },
                    };
                    axios
                        .post("https://dev-2z4p074m.us.auth0.com/oauth/token", bodies, congifs)
                        .then(async (res1) => {
                            localStorage.setItem('access_token', res1?.data?.access_token)
                            localStorage.setItem('auth0-user', JSON.stringify(res))
                            let data = JSON.stringify({
                                "username": res?.nickname,
                                "image": res?.picture,
                                "code": "",
                                "token": res1?.data?.access_token,
                                "userId": res?.sub,
                                "refresh": "",
                                "spotifyID": ""
                            });

                            let config = {
                                method: "get",
                                maxBodyLength: Infinity,
                                url: `${process.env.REACT_APP_BACKEND_URI}/artist/${res?.sub}/data`,
                                headers: {
                                    // authorization: token,
                                }
                            };

                            const response = await axios.request(config);
                            if (response?.data?.data?.length) {
                                console.log('useruseruser 1')
                                callback(user)

                            } else {
                                console.log('useruseruser 2')
                                dispatch(addArtist({ data, callback }))
                            }
                            // fetch(`https://dev-2z4p074m.us.auth0.com/api/v2/users/${res.sub}`, {
                            //     method: "GET",
                            //     headers: {
                            //         Authorization: `Bearer ${res1?.data?.access_token}`,
                            //     },
                            // })
                            //     .then((res) => res.json())
                            //     .then((res2) => { })
                        })
                })
        }
    }, [])

    const stripFunction = async () => {
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PRIVATE_KEY);
        // setLoader(true);
        console.log("runnn");
        let url = new URL(window.location)
        let budget = url.searchParams.get('budget') // retrieve the already set budget
        const stripeSession = await createStripeSession(0.60);
        stripe.redirectToCheckout({ sessionId: stripeSession.sessionId });
    }
    const createStripeSession = async (budget) => {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URI}/artistCampaign/create-stripe-session`,
            {
                budget: Number(budget),
            }
        );
        return response.data;
    };
    useEffect(() => {
        if (accessToken &&
            auth0User) {
            let url = new URL(window.location)
            let redirect = url.searchParams.get('redirect')
            // if(user_data && user_data?.name){
            if (redirect) {
                navigate(redirect)
            } else {
                if (campaigns) {
                    if (campaigns.length) {
                        navigate(`/dashboard`)
                    }
                } else {
                    navigate('/create-campaign/add-track')
                }
            }

        }
    }, [accessToken,
        auth0User, campaigns])
    return (

        <div className='LoaderPageStyle'>
            <div className="loadingPage">
                <h1>Taking you to Songplace</h1>
                <div className="loadingDiv">
                    <div className="loadingCircle"></div>
                </div>
            </div>
        </div>
    )
}

export default Callback